import { FEEDBACK_SKILL } from '../actionType/type';
let intialState = {
    collageList: [],
    courseList: []
}


export default function feedbackReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case FEEDBACK_SKILL:
            stateCopy.skills = action.payload;
            return stateCopy;
        default:
            return stateCopy;

    }
}
