/* eslint-disable no-redeclare */
import {
    GET_ALERTS, UPDATE_ALERTS, GET_POPUP_NOTIFICATION, USER_LOGOUT, ALL_LOGOUT, GET_SEARCH_SUGGESTIONS, GET_NEXT_SEARCH_SUGGESTIONS
} from '../actionType/type';

let intialState = {
    bonus10: false,
    bonus20: false,
    smartBanner: true,
    contest_1: false
}



export default function sidebarReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ALERTS:
            // if (!action.payload.reward) {
            //     stateCopy.smartBanner = action.payload.reward;
            //     stateCopy.contest_1 = false;
            // }
            // else {
            //     this.setState({
            //         smartBanner: action.payload.reward
            //     })
            // }
            // if (action.payload === null) {
            //     stateCopy.openBounsModal = false;
            // }
            // else if (action.payload.bonus10 === false) {
            //     stateCopy.openBounsModal = true;
            //     stateCopy.bonus10 = true;
            // }
            // else if (action.payload.bonus20 === false) {
            //     stateCopy.openBounsModal = true;
            //     stateCopy.bonus10 = false;
            // }
            return stateCopy;
        case UPDATE_ALERTS:
            stateCopy.openBounsModal = false;
            return stateCopy;
        case GET_POPUP_NOTIFICATION:
            stateCopy.notifications = action.payload.data;
            stateCopy.unread = action.payload.unread;
            return stateCopy;
        case USER_LOGOUT:
            localStorage.clear();
            return stateCopy;
        case ALL_LOGOUT:
            localStorage.clear();
            return stateCopy;
        case GET_SEARCH_SUGGESTIONS:
            stateCopy.searchSuggestions = action.payload;
            return stateCopy;
        case GET_NEXT_SEARCH_SUGGESTIONS:
            stateCopy.searchSuggestions = stateCopy.searchSuggestions.concat(action.payload);
            return stateCopy;
        default:
            return stateCopy;

    }
}

