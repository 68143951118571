import { WL_JOB_TITLE, LOGIN_USER, ACCEPT_INVITE, ACCEPT_INVITE_FAILED, LOGIN_ERROR, AUTO_LOGIN, AUTO_EXTERNAL_LOGIN, AUTO_EXTERNAL_LOGIN_FAILED } from '../actionType/type';

let intialState = {
    isAuthorized: false,
    user: localStorage.getItem('profileDetails'),
    succesMessage: false,
    isLoading: true
}

export default function loginReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case WL_JOB_TITLE:
            stateCopy.jobTitles = action.payload;
            return stateCopy;
        case LOGIN_USER:
            stateCopy.isAuthorized = true;
            stateCopy.user = action.payload;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('introComplete', JSON.stringify(action.payload.introComplete))
            localStorage.setItem('profileComplete', action.payload.profile_complete)
            localStorage.setItem('score', action.payload.engagement_score || 0)
            localStorage.setItem('skill_added', JSON.stringify(action.payload.skill_added) === undefined ? false : JSON.stringify(action.payload.skill_added));
            localStorage.setItem('theme', JSON.stringify(action.payload.colors))
            window.location.reload();
            return stateCopy;
        case LOGIN_ERROR:
            stateCopy.succesMessage = true;
            stateCopy.succesMessageText = action.payload;
            return stateCopy;
        case ACCEPT_INVITE:
            stateCopy.isAuthorized = true;
            stateCopy.user = action.payload;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('introComplete', JSON.stringify(action.payload.introComplete));
            localStorage.setItem('profileComplete', JSON.stringify(action.payload.profile_complete));
            localStorage.setItem('skill_added', JSON.stringify(action.payload.skill_added) === undefined ? false : JSON.stringify(action.payload.skill_added));
            localStorage.setItem('theme', JSON.stringify(action.payload.colors))
            window.location.reload();
            return stateCopy;
        case ACCEPT_INVITE_FAILED:
            stateCopy.succesMessage = true;
            stateCopy.succesMessageText = action.payload;
            return stateCopy;
        case AUTO_LOGIN:
            stateCopy.user = action.payload;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('introComplete', JSON.stringify(action.payload.introComplete));
            localStorage.setItem('profileComplete', JSON.stringify(action.payload.profile_complete));
            localStorage.setItem('score', JSON.stringify(action.payload.engagement_score || 0));
            localStorage.setItem('skill_added', JSON.stringify(action.payload.skill_added) === undefined ? false : JSON.stringify(action.payload.skill_added));
            localStorage.setItem('theme', JSON.stringify(action.payload.colors))
            // window.location.reload(); 
            if (!action.payload.profile_complete) {
                window.location.replace('/add-skills')
            }
            else if (action.payload.engagement_score > 20) {
                window.location.replace('/learn')
            }
            else {
                window.location.replace('/getting-started')
            }
            return stateCopy;
        case AUTO_EXTERNAL_LOGIN:
            stateCopy.user = action.payload;
            localStorage.setItem('loginToken', action.payload.token);
            localStorage.setItem('loginEmail', action.payload.email);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload));
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('introComplete', JSON.stringify(action.payload.introComplete));
            localStorage.setItem('profileComplete', JSON.stringify(action.payload.profile_complete));
            localStorage.setItem('score', JSON.stringify(action.payload.engagement_score || 0));
            localStorage.setItem('theme', JSON.stringify(action.payload.colors))
            stateCopy.isLoading = false;
            // window.location.reload();
            action.payload.engagement_score > 20 ? window.location.replace('/learn') : window.location.replace('/getting-started')
            // window.location.href = '/learn'
            return stateCopy;
        case AUTO_EXTERNAL_LOGIN_FAILED:
            stateCopy.mesg = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}
