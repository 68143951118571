import {
    GET_USER_BY_TEAM, GET_USER_BY_ROLE, GET_LOGIN_TRENDS, GET_CONTENT_CONSUMPTION, GET_ENG_SCORE, GET_SKILL_PROGRESS,
    GET_USER_PROGRESS, GET_NEXT_USER_PROGRESS, GET_NEXT_SKILL_PROGRESS
} from '../../actionType/type';

let intialState = {
    userByTeams: [],
    userByRoles: [],
    isLoading: true,
}

export default function adminReportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_USER_BY_TEAM:
            const userCount = action.payload.data.map(el => el.users);
            stateCopy.totalUser = action.payload.total;
            stateCopy.userByTeams = action.payload.data.sort((a, b) => b.users - a.users).slice(0, 10).map((el, i) => [{ y: el.users, color: el.users === Math.max(...userCount) ? '#5fbb97' : '#E7C15F', name: el.name }][0]);
            return stateCopy;
        case GET_USER_BY_ROLE:
            const teamCount = action.payload.map(el => el.users);
            stateCopy.userByRoles = action.payload.sort((a, b) => b.users - a.users).slice(0, 10).map((el, i) => [{ y: el.users, color: el.users === Math.max(...teamCount) ? '#5fbb97' : '#E7C15F', role: el.role }][0]);
            return stateCopy;
        case GET_LOGIN_TRENDS:
            stateCopy.dates = action.payload.dates;
            stateCopy.num_of_logins = action.payload.num_of_logins;
            return stateCopy;
        case GET_CONTENT_CONSUMPTION:
            stateCopy.monthsForEngg = action.payload.months;
            stateCopy.data = action.payload.data;
            return stateCopy;
        case GET_ENG_SCORE:
            stateCopy.num_of_engagementscore = action.payload.num_of_engagementscore;
            stateCopy.date = action.payload.dates;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_SKILL_PROGRESS:
            stateCopy.skillProgress = action.payload;
            return stateCopy;
        case GET_NEXT_SKILL_PROGRESS:
            stateCopy.skillProgress = stateCopy.skillProgress.concat(action.payload);
            return stateCopy;
        case GET_USER_PROGRESS:
            stateCopy.userProgress = action.payload;
            return stateCopy;
        case GET_NEXT_USER_PROGRESS:
            stateCopy.userProgress = stateCopy.userProgress.concat(action.payload);
            return stateCopy;
        default:
            return state;
    }
}