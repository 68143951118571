import { GET_PROFILE, ADD_EDUCATION, GET_COLLEGES, GET_COURSES, CLOSE_SEARCH } from '../actionType/type';
let intialState = {
    collageList: [],
    courseList: []
}


export default function profileReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_PROFILE:
            stateCopy.profiledetails = action.payload;
            stateCopy.education = action.payload.education;
            localStorage.setItem('id', action.payload._id);
            localStorage.setItem('score', action.payload.engagement_score);
            return stateCopy;
        case ADD_EDUCATION:
            return stateCopy;
        case GET_COLLEGES:
            if (action.payload.data.length > 0) {
                stateCopy.collageList = action.payload.data;
                stateCopy.isCollageListLoading = false;
            }
            else {
                let collageEmptyList = [];
                collageEmptyList.push({
                    title: action.payload.college,
                    flag: true,
                });
                // stateCopy.collageList = [];
                stateCopy.collageList = collageEmptyList;
            }
            return stateCopy;
        case CLOSE_SEARCH:
            stateCopy.collageList = [];
            stateCopy.courseList = [];
            return stateCopy;
        case GET_COURSES:
            if (action.payload.data.length > 0) {
                stateCopy.courseList = action.paylaod.data;
                stateCopy.isDegreeListLoading = false;
            }
            else {
                let degreeEmptyList = [];
                degreeEmptyList = [...degreeEmptyList, {
                    title: action.payload.course,
                    flag: true,
                }];
                stateCopy.courseList = degreeEmptyList;
            }
            return stateCopy;
        default:
            return stateCopy;

    }
}
