import React from 'react';

export default function Splash() {
    return (
        <div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', background: '#fff', borderRadius: 10 }}>
                <div style={{ backgroundImage: "url(" + localStorage.getItem('logo') + ")", backgroundPosition: "center", backgroundRepeat: 'no-repeat', padding: 75, backgroundSize: 'contain', borderRadius: 32, width: 125 }}>
                </div>
                <div className="blue-loader">
                    <span className="bar">
                        <span className="progress"></span>
                    </span>
                </div>
            </div>
        </div>
    )
}
