import {
    GET_RELEVENT_SKILL, SUBMIT_URL, GET_CONTENT, GET_NEXT_CONTENT, GET_CONTENT_PERCENT, GET_CONTENTS, GET_CONTENT_STATE, RESET_PERCENT
    , DELETE_CONTENT, SHOW_FEED, CLOSE_FEED, OPEN_SHARE, CLOSE_SHARE, SHARED, GET_CONTENT_SAVED
} from '../actionType/type';

let intialState = {
    skills: { incoming: [], added: [] },
    noResults: false,
    isLoading: true,
    contentDataState: false,
    activeFeedData: {},
    review: false
}

export default function myContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_CONTENT:
            if (action.payload.length >= 1) {
                stateCopy.contents = action.payload;
                stateCopy.noResults = false;
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.noResults = true;
                stateCopy.isLoading = false;
            }
            return stateCopy;
        case GET_NEXT_CONTENT:
            stateCopy.contents = stateCopy.contents.concat(action.payload);
            stateCopy.noResults = false;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_RELEVENT_SKILL:
            if (action.payload.length > 0) {
                stateCopy.skills = { incoming: action.payload, added: [] }
                stateCopy.review = false;
            }
            else {
                stateCopy.skills = { incoming: [], added: [] }
                stateCopy.review = true;
            }
            return stateCopy;
        case SUBMIT_URL:
            stateCopy.skills = { incoming: [], added: [] }
            return stateCopy;
        case GET_CONTENT_STATE:
            stateCopy.contentDataState = action.payload;
            return stateCopy;
        case GET_CONTENT_PERCENT:
            stateCopy.percent = action.payload;
            return stateCopy;
        case GET_CONTENTS:
            stateCopy.documentData = action.payload;
            return stateCopy;
        case RESET_PERCENT:
            // stateCopy.contentDataState = false;
            stateCopy.percent = 0;
            return stateCopy;
        case DELETE_CONTENT:
            const filtered = stateCopy.contents.filter(el => el.content_id !== action.payload)
            stateCopy.contents = filtered;
            return stateCopy;
        case SHOW_FEED:
            stateCopy.activeFeedData = action.payload;
            stateCopy.isOpenFeed = true;
            return stateCopy;
        case CLOSE_FEED:
            stateCopy.activeFeedData = {};
            stateCopy.isOpenFeed = false;
            return stateCopy;
        case OPEN_SHARE:
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case CLOSE_SHARE:
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case SHARED:
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case GET_CONTENT_SAVED:
            stateCopy.contents.forEach(el => {
                if (el._id === action.payload) {
                    el.saved = true;
                }
            }
            )
            return stateCopy;
        default:
            return stateCopy;
    }
}
