import {
    GET_UNLOCK_VALUE, SHOW_PLAYER, HIDE_PLAYER, SHOW_SIDEBAR, GET_SKILLS, GET_SKILL_DATA, RESET_LOADER, GET_SKILL_CUSTOM_PATH, GET_SKILL_PATH, GET_SKILL_POD, POD_RESOURCES_EMPTY, GET_RECOMMENDED_TOPICS, GET_POD_SUBTOPICS, GET_POD_RESOURCES, GET_TOPIC_RESOURCE, RESET_DATA, GET_NEXT_ADDITIONAL_CONTENT, RESET_ADDITIONAL,
    GET_ACTIVE_BTN, GET_SUGGESTED_POD, GET_ADDITIONAL_COUNT, GET_ADDITIONAL_CONTENT, GET_PREVIOUS_RESOURCE, ANALYTIC, GET_LIKE, RESET_RESOURCE_LOADING, RESET_LOADING, GET_RESOURCE_FROM_SUBTOPIC, TEST_AVAILABLITY, GET_LAST_ATTEMPT, GET_FEATURED_MENTOR,
    GET_COURSE, GET_COURSE_DETAILS, GET_ANSWER, GET_QUIZ_HISTORY
} from '../actionType/type';

let intialState = {
    activeBtn: 'all',
    isSkillPathLoading: true,
    note: null,
    resources: null,
    resource: null,
    filterPrevResources: [],
    isShow: false,
    topic: '',
    title: '',
    counts: null,
    isDataLoading: true,
    additionalContent: [],
    filterPrevSuggestedResources: [],
    isLoading: true,
    skillId: null,
    skillPaths: [],
    isResourceLoading: true,
    pods: [],
}


export default function learnReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_UNLOCK_VALUE:
            stateCopy.unlocked = localStorage.getItem('role')?.includes('admin') ? true : action.payload.unlocked;
            // stateCopy.unlocked = action.payload.unlocked;
            stateCopy.proctored_test = action.payload.proctored_test === null ? false : action.payload.proctored_test;
            return stateCopy;
        case GET_SKILLS:
            const skills = action.payload.map(item => ({
                ...item,
                total: item.articles + item.videos + item.podcasts + item.courses + item.documents,
            }));
            stateCopy.currentSkills = skills.filter(el => { return el.current_job }).sort((a, b) => (a.total < b.total) ? 1 : -1);
            stateCopy.aspiringSkills = skills.filter(el => { return (!el.current_job && el.aspiring_job) }).sort((a, b) => (a.total < b.total) ? 1 : -1);
            stateCopy.otherSkills = skills.filter(el => { return (!el.current_job && !el.aspiring_job) }).sort((a, b) => (a.total < b.total) ? 1 : -1);
            stateCopy.skills = action.payload;
            if (action.payload.length) {
                stateCopy.skillObj = { title: action.payload[0].title, id: action.payload[0]._id }
            }

            stateCopy.isLoading = false;
            return stateCopy;
        case SHOW_SIDEBAR:
            stateCopy.isShow = false;
            return stateCopy;
        case GET_SKILL_DATA:
            stateCopy.skillData = action.payload;
            stateCopy.resourceCount = action.payload?.articles + action.payload?.videos + action.payload?.podcasts + action.payload?.courses + action.payload?.documents;
            return stateCopy;
        case RESET_LOADER:
            stateCopy.isLoading = true;
            return stateCopy;
        case GET_SKILL_CUSTOM_PATH:
            stateCopy.customskillPaths = action.payload.length ? action.payload.map(item => ({ ...item, total: item.articles + item.videos + item.podcasts + item.courses + item.documents, skillpods: [] })) : [];
            stateCopy.customskillpathId = action.payload.length ? action.payload[0]._id : '';
            return stateCopy;
        case GET_SKILL_PATH:
            stateCopy.skillPaths = action.payload.length ? action.payload.map(item => ({ ...item, total: item.articles + item.videos + item.podcasts + item.courses + item.documents, skillpods: [] })) : [];
            stateCopy.skillpathId = action.payload.length ? action.payload[0]._id : '';
            // stateCopy.skillPodTitle = '';
            return stateCopy;
        case GET_SKILL_POD:
            if (action.payload.type === 'default') {
                stateCopy.skillPaths.forEach((el, i) => {
                    if (el._id === action.payload.spId) {
                        return el.skillpods = action.payload.data1.map(item => ({ ...item, contents: [] })).sort((a, b) => a.index - b.index);
                    }
                });
                stateCopy.skillPods = action.payload.data1.map(item => ({ ...item, contents: [] })).sort((a, b) => a.index - b.index);
                stateCopy.podId = action.payload.data1.length ? action.payload.data1[0]._id : ''
                stateCopy.skillPodTitle = stateCopy.skillPods[0].title;
                stateCopy.skillpathId = action.payload.spId;
                stateCopy.skillId = action.payload.sid;
            } else {
                stateCopy.customskillPaths.forEach((el, i) => {
                    if (el._id === action.payload.spId) {
                        return el.skillpods = action.payload.data1.map(item => ({ ...item, contents: [] })).sort((a, b) => a.index - b.index);
                    }
                });
                stateCopy.customSkillPods = action.payload.data1.map(item => ({ ...item, contents: [] })).sort((a, b) => a.index - b.index);
                stateCopy.skillPods = action.payload.data1.map(item => ({ ...item, contents: [] })).sort((a, b) => a.index - b.index);
                stateCopy.podId = action.payload.data1.length ? action.payload.data1[0]._id : ''
                stateCopy.skillPodTitle = stateCopy.skillPods[0].title;
                stateCopy.skillpathId = action.payload.spId;
                stateCopy.customSkillId = action.payload.sid;
            }
            stateCopy.isSkillPathLoading = false;

            return stateCopy;
        case RESET_LOADING:
            stateCopy.subtopics = [];
            stateCopy.isResourceLoading = true;
            stateCopy.resources = [];
            stateCopy.courses = [];
            // stateCopy.resource=[];
            return stateCopy;

        case GET_POD_SUBTOPICS:
            stateCopy.pods = action.payload.data.map(el => ({ ...el, resources: [] })).sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.podId = action.payload.id;
            return stateCopy;
        case GET_POD_RESOURCES:
            stateCopy.resources = action.payload.data.resources.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.pods.map((item, index) => {
                const filterData = stateCopy.resources.filter(el => { return el.topic === item.title })
                stateCopy.pods[index].resources = filterData
            });
            stateCopy.isResourceLoading = false;
            if (window.innerWidth > 767) {
                stateCopy.activeBtn = 'all';
                stateCopy.skillPods.forEach((el, i) => {
                    if (el._id === action.payload.id) {
                        return el.contents = action.payload.data.resources.sort((a, b) => (a.index > b.index) ? 1 : -1)
                    }
                });
                stateCopy.podId = action.payload.id;
                stateCopy.resource = action.payload.data.resources.filter(el => { return el.topic === action.payload.skillpod }).sort((a, b) => (a.index > b.index) ? 1 : -1);
                stateCopy.resourceCopy = action.payload.data.resources.filter(el => { return el.topic === action.payload.skillpod }).sort((a, b) => (a.index > b.index) ? 1 : -1);
                stateCopy.counts = getCounts(action.payload.data.resources);
                stateCopy.topic = action.payload.topic;
                let topics = [...new Set(action.payload.data.resources.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => el.topic))];

                stateCopy.topics = topics;
                const content = topics.map(data => { return action.payload.data.resources.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data === el.topic }) });
                stateCopy.subtopics = content;
                stateCopy.isResourceLoading = false;
                stateCopy.isSkillPathLoading = false;
            }
            else {
                stateCopy.topic = action.payload.topic;
                stateCopy.podId = action.payload.id;
                let topics = [...new Set(action.payload.data.resources.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => el.topic))];
                const content = topics.map(data => { return action.payload.data.resources.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data === el.topic }) });
                stateCopy.subtopics = content;
            }
            console.log(stateCopy.subtopics)
            stateCopy.isSkillPathLoading = false;
            return stateCopy;
        case GET_RESOURCE_FROM_SUBTOPIC:
            stateCopy.resource = stateCopy.resources.filter(el => { return el.topic === action.payload }).sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case RESET_RESOURCE_LOADING:
            stateCopy.isResourceLoading = true;
            stateCopy.resource = [];
            return stateCopy;
        case POD_RESOURCES_EMPTY:
            stateCopy.isSkillPathLoading = false;
            return stateCopy;
        case GET_RECOMMENDED_TOPICS:
            const recommended = action.payload.topics.filter(el => el.percentage < 50);

            stateCopy.skillPods.forEach((itemA) => {

                let found = recommended.find((itemB) => {
                    const normalizedTitle1 = removeSpecialChars(itemB.title).trim().toLowerCase();
                    const normalizedTitle2 = removeSpecialChars(itemA.title).trim().toLowerCase();
                    return normalizedTitle1 === normalizedTitle2;
                });
                console.log(found)
                if (found) {
                    itemA.recommend = true;
                } else {
                    itemA.recommend = false;
                }
            });

            // Output the updated arrays
            console.log("Array a:");
            console.log(stateCopy.skillPods);
            console.log("Array b:");
            console.log(recommended)
            return stateCopy;
        case GET_TOPIC_RESOURCE:
            stateCopy.activeBtn = 'all';
            stateCopy.podId = action.payload.id;
            stateCopy.resource = stateCopy.skillPods[action.payload.index]?.contents.filter(el => { return el.topic === action.payload.topic }).sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.resourceCopy = stateCopy.skillPods[action.payload.index]?.contents.filter(el => { return el.topic === action.payload.topic }).sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.counts = getCounts(stateCopy.resource);
            return stateCopy;
        case SHOW_PLAYER:
            if (action.payload.data.allow === false) {
                stateCopy.allow = action.payload.data.allow;
                stateCopy.expireMessage = action.payload.data.message;
            }
            else {
                stateCopy.allow = action.payload.data.allow;
                stateCopy.isShow = (window.innerWidth > 767 && (action.payload.data.data.type === 'Article' || action.payload.data.data.type === 'Podcast' || action.payload.data.data.type === 'Document' || action.payload.data.data.type === 'Quiz')) ? false : true;
                stateCopy.activeFeedData = action.payload.data.data;
                if (action.payload.data.data.type === 'Quiz') {
                    stateCopy.questions = action.payload.data.data?.questions;
                }
                if (action.payload.type === 'suggested') {
                    var found1 = stateCopy.filterPrevSuggestedResources.find(o => o._id === action.payload.data.data?._id);
                    if (found1 === undefined) {
                        stateCopy.filterPrevSuggestedResources = [...stateCopy.filterPrevSuggestedResources, stateCopy.activeFeedData];
                    }
                }
                else {
                    found1 = stateCopy.filterPrevResources.find(o => o._id === action.payload.data.data?._id);
                    if (found1 === undefined) {
                        stateCopy.filterPrevResources = [...stateCopy.filterPrevResources, stateCopy.activeFeedData];
                    }
                }
            }

            return stateCopy;
        case HIDE_PLAYER:
            stateCopy.isShow = false;
            stateCopy.activeFeedData = null;
            return stateCopy;
        case GET_ANSWER:
            // stateCopy.question.correct = action.payload.data.correct;
            // stateCopy.question.correct_id = action.payload.data.correct_id;
            // stateCopy.question.answer = action.payload.aid;

            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    type: answer.type,
                    title: answer.title,
                    answers: answer.answers,
                    explantion: answer.explantion,
                    tags: answer.tags,
                    hints: answer.hints,
                    correct_id: action.payload.data.correct_id,
                    correct: action.payload.data.correct,
                    answer: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case RESET_DATA:
            stateCopy.activeBtn = 'all';
            stateCopy.resource = null;
            stateCopy.counts = null;
            stateCopy.topic = null;
            stateCopy.isDataLoading = true;
            stateCopy.skillPods = [];
            stateCopy.skillPaths = [];
            stateCopy.customskillPaths = [];
            stateCopy.skillData = {};
            stateCopy.isSkillPathLoading = true;
            stateCopy.isResourceLoading = true;
            return stateCopy;
        case GET_QUIZ_HISTORY:
            stateCopy.quizHistory = action.payload;
            return stateCopy;
        case GET_ACTIVE_BTN:
            stateCopy.activeBtn = action.payload.activeBtn;
            if (action.payload.type === 'skillpath') {
                if (action.payload.activeBtn === 'all') {
                    stateCopy.resource = stateCopy.resourceCopy;
                }
                else {
                    stateCopy.resource = stateCopy.resourceCopy.filter(el => { return el.type === action.payload.activeBtn });
                }
            }
            else {
                if (action.payload.activeBtn === 'all') {
                    stateCopy.suggestedPod = stateCopy.suggestedPodCopy;
                }
                else {
                    stateCopy.suggestedPod = stateCopy.suggestedPodCopy.filter(el => { return el.type === action.payload.activeBtn });
                }
            }
            return stateCopy;
        case GET_SUGGESTED_POD:
            stateCopy.activeBtn = 'all';
            stateCopy.suggestedPod = action.payload.resources.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.suggestedPodCopy = action.payload.resources.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.topic = action.payload.title;
            stateCopy.podId = action.payload._id;
            stateCopy.suggestedPodcounts = getCounts(stateCopy.suggestedPod);
            stateCopy.isDataLoading = false;
            return stateCopy;
        case GET_ADDITIONAL_COUNT:
            stateCopy.contentCount = action.payload;
            return stateCopy;
        case RESET_ADDITIONAL:
            stateCopy.isDataLoading = true;
            return stateCopy;
        case GET_ADDITIONAL_CONTENT:
            stateCopy.additionalContent = action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => ({ ...el, type: action.payload.type }));
            stateCopy.podId = null;
            stateCopy.isDataLoading = false;
            return stateCopy;
        case GET_NEXT_ADDITIONAL_CONTENT:
            stateCopy.additionalContent = stateCopy.additionalContent.concat(action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => ({ ...el, type: action.payload.type })));
            stateCopy.isDataLoading = false;
            stateCopy.podId = null;
            return stateCopy;
        case GET_PREVIOUS_RESOURCE:
            if (action.payload.type === 'suggested') {
                stateCopy.activeFeedData = stateCopy.filterPrevSuggestedResources.filter(el => el._id === action.payload.details._id.toString())[0];
            }
            else {
                stateCopy.activeFeedData = stateCopy.filterPrevResources.filter(el => el._id === action.payload.details._id.toString())[0];
            }
            return stateCopy;
        case ANALYTIC:
            if (window.innerWidth > 767) {
                stateCopy.pods.map((item, index) => {
                    const analytics = item.resources.map(answer => {
                        if (answer._id === action.payload.r_id) return {
                            _id: answer._id,
                            title: answer.title,
                            source: answer.source,
                            type: answer.type,
                            skill: answer.skill,
                            liked: answer.liked,
                            topic: answer.topic,
                            image: answer.image,
                            index: answer.index,
                            duration: answer.duration,
                            viewed: answer.viewed ? true : action.payload.viewed,
                            // recommended: answer.recommended
                        }
                        return answer
                    })
                    stateCopy.pods[index].resources = analytics
                });
            } else {
                stateCopy.subtopics.map((item, index) => {
                    const analytics = item.map(answer => {
                        if (answer._id === action.payload.r_id) return {
                            _id: answer._id,
                            title: answer.title,
                            source: answer.source,
                            type: answer.type,
                            skill: answer.skill,
                            liked: answer.liked,
                            topic: answer.topic,
                            image: answer.image,
                            index: answer.index,
                            duration: answer.duration,
                            viewed: answer.viewed ? true : action.payload.viewed,
                            // recommended: answer.recommended
                        }
                        return answer
                    })
                    stateCopy.subtopics[index] = analytics
                });
            }
            if (action.payload._id === null) {
                const analytics = stateCopy.additionalContent.map(answer => {
                    if (answer._id === action.payload.r_id) return {
                        _id: answer._id,
                        title: answer.title,
                        source: answer.source,
                        type: answer.type,
                        skill: answer.skill,
                        liked: answer.liked,
                        topic: answer.topic,
                        image: answer.image,
                        index: answer.index,
                        duration: answer.duration,
                        viewed: answer.viewed ? true : action.payload.viewed,
                        // recommended: answer.recommended
                    }
                    return answer
                })
                stateCopy.additionalContent = analytics;
            }
            return stateCopy;
        case GET_LIKE:
            stateCopy.activeFeedData.liked = !action.payload.liked;
            return stateCopy;
        case GET_LAST_ATTEMPT:
            stateCopy.percentage = action.payload?.start;
            return stateCopy;
        case TEST_AVAILABLITY:
            stateCopy.testExist = action.payload.testExist;
            stateCopy.testDetails = action.payload.testDetails;
            // stateCopy.testAttempts = action.payload.data.filter(el => el?.score >= 0);
            stateCopy.testAttempts = action.payload.data;
            return stateCopy;
        case GET_FEATURED_MENTOR:
            stateCopy.featuredMentor = action.payload;
            return stateCopy;
        case GET_COURSE:
            const coursesStatusStarted = action.payload?.userCourses.concat(action.payload.courses).filter(el => el.status === 'started');
            const coursesStatusCompleted = action.payload?.userCourses.concat(action.payload.courses).filter(el => el.status === 'completed');
            const coursesStatusnull = action.payload?.userCourses.concat(action.payload.courses).filter(el => (el.status === 'not_interested' || el.status == null));
            stateCopy.courses = window.location.origin === 'https://learning.saifeehospital.com' ? coursesStatusStarted.concat(coursesStatusCompleted).concat(coursesStatusnull).filter(el => (el.is_paid || (el.is_paid === undefined))) : coursesStatusStarted.concat(coursesStatusCompleted).concat(coursesStatusnull);
            return stateCopy;
        case GET_COURSE_DETAILS:
            stateCopy.courseDetail = action.payload[0];
            return stateCopy;
        default:
            return stateCopy;

    }
}

const getCounts = (data) => {
    const video = data.filter(el => el.type === 'Video').length;
    const article = data.filter(el => el.type === 'Article').length;
    const course = data.filter(el => el.type === 'Course').length;
    const podcast = data.filter(el => el.type === 'Podcast').length;
    const total = data.length;
    let obj = {
        video, article, course, podcast, total
    }
    return obj;
}

const removeSpecialChars = (str) => {
    return str.replace(/[^a-zA-Z\s]/g, '');
}