import {
    GET_REWARD_POINTS, GET_COUNTRY, GET_REWARDS, GET_REWARD_HISTORY, GET_NEXT_REWARD_HISTORY, OPEN_REDEEM_MODAL, CLOSE_REDEEM_MODAL
    , COUPON_REDEEMED, CLOSE_GREET_MODAL, TEST_REDEEMED, SUCCESS_MODAL_CLOSE
} from '../actionType/type';

let intialState = {
    openSuccesModal: false,
    greetModal: false
}




export default function rewardsReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_REWARD_POINTS:
            stateCopy.points = action.payload;
            return stateCopy;
        case GET_COUNTRY:
            stateCopy.currency = action.payload.currency;
            stateCopy.country = action.payload.code;
            return stateCopy;
        case GET_REWARDS:
            stateCopy.rewards = action.payload;
            return stateCopy;
        case GET_REWARD_HISTORY:
            stateCopy.rewardHistory = action.payload;
            return stateCopy;
        case GET_NEXT_REWARD_HISTORY:
            stateCopy.rewardHistory = stateCopy.rewardHistory.concat(action.payload);
            return stateCopy;
        case OPEN_REDEEM_MODAL:
            stateCopy.isOpenRedeem = true;
            stateCopy.cardDetails = action.payload;
            return stateCopy;
        case CLOSE_REDEEM_MODAL:
            stateCopy.isOpenRedeem = false;
            stateCopy.cardDetails = [];
            return stateCopy;
        case COUPON_REDEEMED:
            stateCopy.isOpenRedeem = false;
            stateCopy.greetModal = true;
            return stateCopy;
        case CLOSE_GREET_MODAL:
            stateCopy.greetModal = false;
            return stateCopy;
        case TEST_REDEEMED:
            stateCopy.openSuccesModal = true;
            return stateCopy;
        case SUCCESS_MODAL_CLOSE:
            stateCopy.openSuccesModal = false;
            return stateCopy;
        default:
            return stateCopy;

    }
}