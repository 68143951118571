import {
    SET_ACTIVE_BTN, GET_ADMIN_TEST, GET_NEXT_ADMIN_TEST, GET_ADMIN_V2_TEST, RESET_TEST_LOADING, ACTION_DONE,
    SEARCH_QUIZ, GET_QUIZZES,
    SEARCH_TEST, GET_CUSTOM_TESTS, Add_Question_Quiz, Delete_Question_Quiz, Delete_All_Question_Quiz, CHANGE_TEST_STATUS,
    GET_QUESTION_SKILLS, SEARCH_SKILLS, BACK_TO_VIEW, GET_QUESTIONS_FROM_SKILL, SEARCH_QUESTION, UPDATE_QUESTION,
    RESET_QUES_DATA, SET_QUESTION_TITLE, SET_EXPLAINTION, SET_CHOICE1, SET_CHOICE2, SET_CHOICE3, SET_CHOICE4, GET_DATA_FOR_EDIT,
} from '../../actionType/type';
let intialState = {
    isInviteSent: false,
    isTestLoading: true,
    activeBtn: 'test-report',
    qSkillId: '',
    quizzes: [],
    skillList: [],
    customTests: [],
    questionListForQuiz: [],
    isViewed: false,
    isSkillLoading: true,
    questions: [],
    qTitle: '',
    qExplanation: '',
    choice1: '',
    choice2: '',
    choice3: '',
    choice4: '',

}

export default function adminTestReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case SET_ACTIVE_BTN:
            stateCopy.activeBtn = action.payload;
            stateCopy.qTitle = '';
            stateCopy.choice1 = '';
            stateCopy.choice2 = '';
            stateCopy.choice3 = '';
            stateCopy.choice4 = '';
            stateCopy.qExplanation = '';
            return stateCopy;
        case GET_ADMIN_TEST:
            stateCopy.testList = action.payload.data;
            stateCopy.credit_points = action.payload.credit_points
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_NEXT_ADMIN_TEST:
            stateCopy.testList = stateCopy.testList.concat(action.payload.data);
            stateCopy.isTestLoading = false;
            return stateCopy;
        case GET_ADMIN_V2_TEST:
            stateCopy.v2TestList = action.payload;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case RESET_TEST_LOADING:
            stateCopy.isTestLoading = true;
            stateCopy.isViewed = false;
            return stateCopy;
        case ACTION_DONE:
            stateCopy.credit_points = action.payload.credit;
            const answersns = stateCopy.testList.map(answer => {
                if (answer._id === action.payload.obj._id) return { _id: answer._id, name: answer.name, email: answer.email, test: answer.test, skill: answer.skill, date: answer.date, time: answer.time, status: action.payload.obj.approve ? 'approved' : 'rejected', url: answer.url, score: answer.score, certificate: answer.certificate, report: answer.report, otp: answer.otp }
                return answer
            });
            stateCopy.testList = answersns;
            return stateCopy;
        case GET_QUIZZES:
            stateCopy.quizzes = action.payload;
            stateCopy.quizzesCopy = action.payload;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case SEARCH_QUIZ:
            if (action.payload.trim('') !== '') {
                const regex = new RegExp(action.payload, "i");
                const filteredQuiz = stateCopy.quizzesCopy.filter(
                    skill => {
                        return regex.test(skill.title)
                    }
                );
                stateCopy.quizzes = filteredQuiz;
            } else {
                stateCopy.quizzes = stateCopy.quizzesCopy;
            }
            return stateCopy;

        //custom tests  
        case GET_CUSTOM_TESTS:
            stateCopy.customTests = action.payload;
            stateCopy.customTestsCopy = action.payload;
            stateCopy.isTestLoading = false;
            return stateCopy;
        case SEARCH_TEST:
            if (action.payload.trim('') !== '') {
                const regex = new RegExp(action.payload, "i");
                const filteredTest = stateCopy.customTestsCopy.filter(
                    skill => {
                        return regex.test(skill.title)
                    }
                );
                stateCopy.customTests = filteredTest;
            } else {
                stateCopy.customTests = stateCopy.customTestsCopy;
            }
            return stateCopy;
        case CHANGE_TEST_STATUS:
            if (action.payload.type === 'Test') {
                stateCopy.customTests.forEach((el) => {
                    if (el._id === action.payload.id) {
                        el.active = !action.payload.active
                    }
                })
            } else {
                stateCopy.quizzes.forEach((el) => {
                    if (el._id === action.payload.id) {
                        el.active = !action.payload.active
                    }
                })
            }
            return stateCopy;
        case Add_Question_Quiz:
            const updatedQuestionList = stateCopy.questions.map((question) => {
                if (question._id === action.payload._id) {
                    return { ...question, checked: true };
                }
                return question;
            });
            let newData = {
                ...stateCopy,
                questions: [...updatedQuestionList],
                questionListForQuiz: [...stateCopy.questionListForQuiz, action.payload],
            };
            return newData;
        case Delete_Question_Quiz:
            const dataSource = [...stateCopy.questionListForQuiz];
            const filteredQuestions = dataSource.filter(
                (allQuestions) => allQuestions._id !== action.payload
            );
            return {
                ...stateCopy,
                questions: [
                    ...stateCopy.questions.map((question) => {
                        if (question._id === action.payload) {
                            return { ...question, checked: false };
                        }
                        return question;
                    }),
                ],
                questionListForQuiz: filteredQuestions,
            };
        case Delete_All_Question_Quiz:
            stateCopy.questionListForQuiz = [];
            return stateCopy;
        //Question Bank    
        case BACK_TO_VIEW:
            stateCopy.isViewed = false;
            return stateCopy;
        case GET_QUESTION_SKILLS:
            stateCopy.skillList = action.payload;
            stateCopy.skillListCopy = action.payload;
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case GET_QUESTIONS_FROM_SKILL:
            stateCopy.questions = action.payload.data;
            stateCopy.questionsCopy = action.payload.data;
            stateCopy.qSkillId = action.payload.id;
            stateCopy.isViewed = true;
            return stateCopy;
        case GET_DATA_FOR_EDIT:
            stateCopy.editData = action.payload.data;
            if (action.payload.type === 'question') {
                stateCopy.questionData = action.payload.data;
                stateCopy.qTitle = action.payload.data.title;
                stateCopy.qExplanation = action.payload.data.explanation;
            } else {
                stateCopy.questionListForQuiz = action.payload.data.questions;
            }
            return stateCopy;
        case RESET_QUES_DATA:
            stateCopy.qTitle = '';
            stateCopy.choice1 = '';
            stateCopy.choice2 = '';
            stateCopy.choice3 = '';
            stateCopy.choice4 = '';
            stateCopy.qExplanation = '';
            return stateCopy;
        case SET_QUESTION_TITLE:
            stateCopy.qTitle = action.payload;
            return stateCopy;
        case SET_EXPLAINTION:
            stateCopy.qExplanation = action.payload;
            return stateCopy;
        case SET_CHOICE1:
            stateCopy.choice1 = action.payload;
            return stateCopy;
        case SET_CHOICE2:
            stateCopy.choice2 = action.payload;
            return stateCopy;
        case SET_CHOICE3:
            stateCopy.choice3 = action.payload;
            return stateCopy;
        case SET_CHOICE4:
            stateCopy.choice4 = action.payload;
            return stateCopy;
        case UPDATE_QUESTION:
            stateCopy.isViewed = false;
            return stateCopy;
        case SEARCH_SKILLS:
            if (action.payload !== '') {
                const filteredSkills = stateCopy.skillListCopy.filter(
                    skill => {
                        return (
                            skill.title.toLowerCase().includes(action.payload.toLowerCase())
                        );
                    }
                );
                stateCopy.skillList = filteredSkills;
            } else {
                stateCopy.skillList = stateCopy.skillListCopy;
            }
            return stateCopy;
        case SEARCH_QUESTION:
            if (action.payload !== '') {
                const regex = new RegExp(action.payload, "i");
                const filteredQuestion = stateCopy.questionsCopy.filter(
                    skill => {
                        return regex.test(skill.title)
                    }
                );
                stateCopy.questions = filteredQuestion;
            } else {
                stateCopy.questions = stateCopy.questionsCopy;
            }
            return stateCopy;
        default:
            return stateCopy;
    }
}