import {
  GET_USER_LIST, GET_TEAMS, GET_NEXT_USER_LIST, CHANGE_STATUS, GET_USER_SKILLS, SEARCH_JOB_TITLES, CHECK_USER, INVITE_SENT, TEAM_ADDED, SET_ACTIVE, GET_USERS_DATA,
  GET_TEAM_USER, REMOVE_FROM_TEAM, DELETE_TEAM, SORT_TEAMS, DETAILS_UPDATE
} from "../../actionType/type";

let intialState = {
  userList: [],
  isLoading: true,
  invite: false,
  activeTab: 'users',
  skills: [],
  skillsCopy: []
};

export default function userReducer(state = intialState, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case GET_USER_LIST:
      stateCopy.userList = action.payload.data;
      stateCopy.total = action.payload.total;
      stateCopy.user_count = action.payload.user_count;
      stateCopy.isLoading = false;
      return stateCopy;
    case GET_TEAMS:
      stateCopy.teams = action.payload.sort((a, b) => a.engagement_score < b.engagement_score ? 1 : -1);
      stateCopy.isLoading = false;
      return stateCopy;
    case GET_NEXT_USER_LIST:
      stateCopy.userList = stateCopy.userList.concat(action.payload.data);
      stateCopy.total = action.payload.total;
      return stateCopy;
    case CHANGE_STATUS:
      stateCopy.userList.forEach((answer) => {
        if (answer._id === action.payload.id) { answer.status = action.payload.checked ? "active" : "inactive"; }
      });
      return stateCopy;
    case GET_USER_SKILLS:
      stateCopy.skills = action.payload.map((el) => [{ ...el, value: el._id, label: el.title }][0]);
      stateCopy.skillsCopy = action.payload.map((el) => [{ ...el, value: el._id, label: el.title }][0]);
      return stateCopy;
    case SEARCH_JOB_TITLES:
      if (action.payload === "") {
        stateCopy.skills = stateCopy.skillsCopy;
      } else {
        const filterResult = stateCopy.skillsCopy.filter((el) => {
          return el.label.toLowerCase().includes(action.payload.toLowerCase());
        });
        if (filterResult?.length) {
          stateCopy.skills = filterResult;
        } else {
          stateCopy.skills = []
        }
      }

      return stateCopy;
    case CHECK_USER:
      stateCopy.exists = action.payload?.length ? true : false;
      return stateCopy;
    case DETAILS_UPDATE:
      if (action.payload.status === 'invited') {
        stateCopy.userList.forEach((answer) => {
          const { user_id, firstName, lastName, current_job, email } = action.payload
          if (answer._id === user_id) {
            answer.name = `${firstName} ${lastName}`
            answer.firstName = firstName
            answer.lastName = lastName
            answer.current_job = { _id: current_job?.value, title: current_job?.label }
            answer.email = email
          }
        });
      } else {
        stateCopy.userList.forEach((answer) => {
          const { firstName, lastName, current_job } = action.payload
          if (answer._id === action.payload.user_id) {
            answer.name = `${firstName} ${lastName}`
            answer.firstName = firstName
            answer.lastName = lastName
            answer.current_job = { _id: current_job?.value, title: current_job?.label }
          }
        });
      }
      return stateCopy;
    case INVITE_SENT:
      stateCopy.invite = true;
      return stateCopy;
    case SET_ACTIVE:
      stateCopy.activeTab = action.payload;
      return stateCopy;
    case TEAM_ADDED:
      stateCopy.team = action.payload;
      return stateCopy;
    case GET_TEAM_USER:
      stateCopy.teamUsers = action.payload;
      return stateCopy;
    case REMOVE_FROM_TEAM:
      const filtered = stateCopy.teamUsers.filter(
        (el) => el._id !== action.payload.user_id
      );
      stateCopy.teamUsers = filtered;
      return stateCopy;
    case DELETE_TEAM:
      let filteredTeam = stateCopy.teams.filter(
        (el) => el._id !== action.payload
      );
      stateCopy.teams = filteredTeam;
      return stateCopy;
    case SORT_TEAMS:
      if (action.payload.sortKey === "engagement") {
        const data = getSortData(
          action.payload.asc,
          action.payload.sortKey,
          stateCopy.teams
        );
        stateCopy.teams = data;
      } else if (action.payload.sortKey === "member") {
        const data = getSortData(
          action.payload.asc,
          action.payload.sortKey,
          stateCopy.teams
        );
        stateCopy.teams = data;
      }
      return stateCopy;
    case GET_USERS_DATA:
      stateCopy.usersData = action.payload;
      return stateCopy;
    default:
      return stateCopy;
  }
}

function getSortData(asc, key, arr) {
  if (asc) {
    return arr.sort((a, b) => (a[key] < b[key] ? 1 : -1));
  } else {
    return arr.sort((a, b) => (a[key] > b[key] ? 1 : -1));
  }
}
