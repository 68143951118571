import { GET_QUESTIONS, GET_QUESTION, CLOSED_TEST, GET_TEST_ANSWERS, GET_SCORE, GET_IMAGE_UPLOAD } from "../actionType/type";
let intialState = {
  quizDetails: {},
  questions: [],
  isQuiz: false,

};

export default function proctoredTestReducer(state = intialState, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case GET_QUESTIONS:
      stateCopy.quizDetails = action.payload;
      stateCopy.questions = action.payload.data;
      stateCopy.isQuiz = true;
      stateCopy.questions.forEach((object) => {
        object.answered = null;
        object.correct_id = null;
        object.correct = null;
      });
      stateCopy.question = stateCopy.questions[0];
      return stateCopy;
    case GET_QUESTION:
      stateCopy.question = action.payload;
      return stateCopy;
    case CLOSED_TEST:
      stateCopy.isQuiz = false;
      return stateCopy;
    case GET_TEST_ANSWERS:
      stateCopy.questions.forEach((answer) => {
        if (answer._id === action.payload.qId) {
          answer.answered = action.payload.aId
        }
      });
      stateCopy.question['answered'] = action.payload.aId;
      return stateCopy;
    case GET_SCORE:
      stateCopy.score = action.payload.score;
      return stateCopy;
    case GET_IMAGE_UPLOAD:
      stateCopy.image = action.payload.image;
      return stateCopy;
    default:
      return stateCopy;
  }
}
