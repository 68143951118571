import {
    GET_CONTENT_LIST, GET_NEXT_CONTENT_LIST, APPROVED, RESET_CONTENT, CHANGE_CONTENT_STATUS
} from '../../actionType/type';
let intialState = {
    contents: [],
    isLoading: true
}

export default function adminContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_CONTENT_LIST:
            stateCopy.contents = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_CONTENT_LIST:
            stateCopy.contents = stateCopy.contents.concat(action.payload);
            return stateCopy;
        case APPROVED:
            stateCopy.contents.forEach(el => {
                if (el._id === action.payload._id) {
                    el.review = action.payload.review ? 'approved' : 'rejected'
                }
            });
            return stateCopy;
        case RESET_CONTENT:
            stateCopy.isLoading = true;
            return stateCopy;
        case CHANGE_CONTENT_STATUS:
            stateCopy.contents.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = action.payload.checked ? true : false;
                }
            });
            return stateCopy;
        default:
            return state;
    }
}