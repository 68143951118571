import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store";
import './i18n';
import Splash from './Components/splash';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = React.lazy(() => import('./App'));
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <React.Suspense fallback={(window.location.href.includes('external-login') || window.location.href === '/add-skills') ? null : <Splash />}>
        <GoogleOAuthProvider clientId="1023316346256-n6kkfdfnl2bnp58450bik2eifo300a6o.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </React.Suspense>
    </React.StrictMode>
  </Provider >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
