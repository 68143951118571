import { combineReducers } from "redux";
import addSkillReducer from "./addSkillReducer";
import adminTestReducer from "./adminReducer/adminTestReducer";
import errorReducer from "./errorReducer";
import feedsReducer from "./feedsReducer";
import learnReducer from "./learnReducer";
import loginReducer from "./loginReducer";
import myContentReducer from "./myContentReducer";
import notificationReducer from "./notificationReducer";
import rewardsReducer from "./rewardReducer";
import savedReducer from "./savedReducer";
import testReducer from "./testReducer";
import userReducer from "./adminReducer/userReducer";
import shareReducer from "./shareReducer";
import analyticReducer from "./analyticReducer";
import adminContentReducer from "./adminReducer/adminContentReducer";
import sidebarReducer from "./sidebarReducer";
import profileReducer from "./profileReducer";
import adminRewardReducer from "./adminReducer/adminrewardReducer";
import accountReducer from "./adminReducer/accountReducer";
import jobTitleReducer from "./adminReducer/jobTitleReducer";
import searchReducer from "./searchReducer";
import SkillLibraryReducer from "./skillLibraryReducer";
import proctoredTestReducer from "./proctoredTestReducer";
import adminReportReducer from "./adminReducer/reportReducer";
import feedbackReducer from "./feedbackReducer";
import manageSkillPathReducer from "./adminReducer/manageSkillPathReducer";
import manageMentorReducer from "./adminReducer/manageMentorReducer";



export const rootReducer = combineReducers({
    login: loginReducer,
    sidebar: sidebarReducer,
    profile: profileReducer,
    addSkill: addSkillReducer,
    test: testReducer,
    rewards: rewardsReducer,
    feeds: feedsReducer,
    learn: learnReducer,
    analytic: analyticReducer,
    mycontent: myContentReducer,
    error: errorReducer,
    notification: notificationReducer,
    search: searchReducer,
    skillLibrary: SkillLibraryReducer,
    skill: addSkillReducer,
    saved: savedReducer,
    share: shareReducer,
    users: userReducer,
    adminTest: adminTestReducer,
    content: adminContentReducer,
    adminReward: adminRewardReducer,
    account: accountReducer,
    jobtitle: jobTitleReducer,
    proctored: proctoredTestReducer,
    report: adminReportReducer,
    feedback: feedbackReducer,
    manageSkillpath: manageSkillPathReducer,
    manageMentor: manageMentorReducer
});