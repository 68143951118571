/* eslint-disable no-redeclare */
import {
    SELECT_ALL, SELECT_ONE
} from '../actionType/type';

let intialState = {
    userList: [
        {
            "name": "rajat j",
            "email": "rajat87@gmail.com",
            "profile_image": null,
            "initial": "RJ",
            "color": "#6457e8",
            isChecked: false
        },
        {
            "name": "rajat j",
            "email": "rajat@elite.com",
            "profile_image": null,
            "initial": "RJ",
            "color": "#6457e8",
            isChecked: false
        },
        {
            "name": "rajat jain",
            "email": "rajatenter@gmail.com",
            "profile_image": null,
            "initial": "rj",
            "color": "#fe5722",
            isChecked: false
        },
        {
            "name": "rajat jain",
            "email": "rajatenter@gmail.com",
            "profile_image": null,
            "initial": "rj",
            "color": "#fe5722",
            isChecked: false
        },
        {
            "name": "rajat jain",
            "email": "rajatenter@gmail.com",
            "profile_image": null,
            "initial": "rj",
            "color": "#fe5722",
            isChecked: false
        }
    ],
}



export default function shareReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case SELECT_ALL:
            if (action.payload) {
                stateCopy.userList = stateCopy.userList.map(item => ({ ...item, isChecked: true }));
            }
            else {
                stateCopy.userList = stateCopy.userList.map(item => ({ ...item, isChecked: false }));
            }
            return stateCopy;
        case SELECT_ONE:
            if (action.payload.checked) {
                stateCopy.userList.forEach(el => {
                    if (el.email === action.payload.email) {
                        el.isChecked = true
                    }
                })
            }
            else {
                stateCopy.userList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = false
                    }
                })
            }
            return stateCopy;
        default:
            return stateCopy;

    }
}

