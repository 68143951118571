import { GET_NEXT_NOTIFICATION, GET_NOTIFICATION, MARKED_NOTIFICATION } from '../actionType/type';
let intialState = {
    notifications: [],
    isLoading: true,
    noResults: false
}


export default function notificationReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_NOTIFICATION:
            if (action.payload.length > 0) {
                stateCopy.notifications = action.payload;
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.noResults = true;
            }
            return stateCopy;
        case GET_NEXT_NOTIFICATION:
            stateCopy.notifications = stateCopy.notifications.concat(action.payload);
            stateCopy.isLoading = false;
            return stateCopy;
        case MARKED_NOTIFICATION:
            window.location.href = action.payload;
            return stateCopy;
        default:
            return stateCopy;

    }
}
