import {
    GET_ALL_SKILLS, GET_NEXT_ALL_SKILLS
} from '../actionType/type';

let intialState = {
    isSkillLoading: true
}




export default function SkillLibraryReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ALL_SKILLS:
            stateCopy.skills = action.payload.map(item => ({ ...item, total: item.articles + item.videos + item.courses + item.documents + item.podcasts })).filter(el => el.total !== 0);
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case GET_NEXT_ALL_SKILLS:
            stateCopy.skills = stateCopy.skills.concat(action.payload.map(item => ({ ...item, total: item.articles + item.videos + item.courses + item.documents + item.podcasts }))).filter(el => el.total !== 0);
            stateCopy.isSkillLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}