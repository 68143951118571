//Login
export const LOGIN_USER = 'LOGIN_USER';
export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCEPT_INVITE_FAILED = 'ACCEPT_INVITE_FAILED';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const AUTO_EXTERNAL_LOGIN = 'AUTO_EXTERNAL_LOGIN';
export const AUTO_EXTERNAL_LOGIN_FAILED = 'AUTO_EXTERNAL_LOGIN_FAILED';
export const WL_JOB_TITLE = 'WL_JOB_TITLE';
export const SIGNUP_USER = 'SIGNUP_USER';
//Add skill
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const GET_CURRENT_JOB = 'GET_CURRENT_JOB';
export const GET_ASPIRING_JOB = 'GET_ASPIRING_JOB';
export const GET_SUGGESTED_ROLE = 'GET_SUGGESTED_ROLE';
export const SAVE_CURRENT_JOB = 'SAVE_CURRENT_JOB';
export const SAVE_ASPIRING_JOB = 'SAVE_ASPIRING_JOB';
export const GET_SUGGESTED_ROLE_SKILL = 'GET_SUGGESTED_ROLE_SKILL';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const GET_SUGGESTED_SKILL = 'GET_SUGGESTED_SKILL';
export const CLOSE_SKILL_SUGGESTION = 'CLOSE_SKILL_SUGGESTION';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE1 = 'SAVE_PROFILE1';
export const SAVE_SKILLS = 'SAVE_SKILLS';

//sidebar
export const GET_POPUP_NOTIFICATION = 'GET_POPUP_NOTIFICATION';
export const GET_ALERTS = 'GET_ALERTS';
export const UPDATE_ALERTS = 'UPDATE_ALERTS';
export const MARKED_NOTIFICATION = 'MARKED_NOTIFICATION';
export const TRACK_USER = 'TRACK_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ALL_LOGOUT = 'ALL_LOGOUT';
export const GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS';
export const GET_NEXT_SEARCH_SUGGESTIONS = 'GET_NEXT_SEARCH_SUGGESTIONS';

//LEARN 
export const GET_UNLOCK_VALUE = 'GET_UNLOCK_VALUE';
export const SHOW_PLAYER = 'SHOW_PLAYER';
export const HIDE_PLAYER = 'HIDE_PLAYER';
export const GET_SKILLS = 'GET_SKILLS';
export const GET_SKILL_DATA = 'GET_SKILL_DATA';
export const GET_SKILL_CUSTOM_PATH = 'GET_SKILL_CUSTOM_PATH';
export const GET_SKILL_PATH = 'GET_SKILL_PATH';
export const GET_SKILL_POD = 'GET_SKILL_POD';
export const POD_RESOURCES_EMPTY = 'POD_RESOURCES_EMPTY';
export const GET_RECOMMENDED_TOPICS = 'GET_RECOMMENDED_TOPICS';
export const RESET_SKILLPATH = 'RESET_SKILLPATH';
export const GET_POD_RESOURCES = 'GET_POD_RESOURCES';
export const GET_POD_SUBTOPICS = 'GET_POD_SUBTOPICS';
export const GET_TOPIC_RESOURCE = 'GET_TOPIC_RESOURCE';
export const GET_TOPIC_RESOURCE1 = 'GET_TOPIC_RESOURCE1';
export const RESET_DATA = 'RESET_DATA';
export const GET_ACTIVE_BTN = 'GET_ACTIVE_BTN';
export const GET_SUGGESTED_POD = 'GET_SUGGESTED_POD';
export const GET_ADDITIONAL_COUNT = 'GET_ADDITIONAL_COUNT';
export const RESET_ADDITIONAL = 'RESET_ADDITIONAL';
export const GET_ADDITIONAL_CONTENT = 'GET_ADDITIONAL_CONTENT';
export const GET_NEXT_ADDITIONAL_CONTENT = 'GET_NEXT_ADDITIONAL_CONTENT';
export const GET_PREVIOUS_RESOURCE = 'GET_PREVIOUS_RESOURCE';
export const ANALYTIC = 'ANALYTIC';
export const RESET_LOADER = 'RESET_LOADER';
export const GET_LIKE = 'GET_LIKE';
export const RESET_LOADING = 'RESET_LOADING';
export const RESET_RESOURCE_LOADING = 'RESET_RESOURCE_LOADING';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_RESOURCE_FROM_SUBTOPIC = 'GET_RESOURCE_FROM_SUBTOPIC';
export const TEST_AVAILABLITY = 'TEST_AVAILABLITY';
export const GET_LAST_ATTEMPT = 'GET_LAST_ATTEMPT';
export const GET_FEATURED_MENTOR = 'GET_FEATURED_MENTOR';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const GET_ANSWER = 'GET_ANSWER';
export const GET_QUIZ_HISTORY = 'GET_QUIZ_HISTORY';


//Error
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';




//feeds
export const GET_SKILL_FILTER = 'GET_SKILL_FILTER';
export const GET_FEEDS = 'GET_FEEDS';
export const GET_NEXT_FEEDS = 'GET_NEXT_FEEDS';
export const SKILL_CLICKED = 'SKILL_CLICKED';
export const GET_FILTERED_FEEDS = 'GET_FILTERED_FEEDS';
export const RESET_FEEDS = 'RESET_FEEDS';
export const GET_SAVED = 'GET_SAVED';
export const OPEN_SHARE = 'OPEN_SHARE';
export const CLOSE_SHARE = 'CLOSE_SHARE';
export const SHARED = 'SHARED';
export const GET_RESOURCE_DETAIL = 'GET_RESOURCE_DETAIL';
export const GET_FEED_LIKES = 'GET_FEED_LIKES';
export const SHOW_FEED = 'SHOW_FEED';
export const CLOSE_FEED = 'CLOSE_FEED';
export const FEED_ANALYTIC = 'FEED_ANALYTIC';

//share
export const SELECT_ALL = 'SELECT_ALL';
export const SELECT_ONE = 'SELECT_ONE';


//rewards
export const GET_REWARD_POINTS = 'GET_REWARD_POINTS';
export const GET_REWARDS = 'GET_REWARDS';
export const GET_REWARD_HISTORY = 'GET_REWARD_HISTORY';
export const GET_NEXT_REWARD_HISTORY = 'GET_NEXT_REWARD_HISTORY';
export const OPEN_REDEEM_MODAL = 'OPEN_REDEEM_MODAL';
export const CLOSE_REDEEM_MODAL = 'CLOSE_REDEEM_MODAL';
export const COUPON_REDEEMED = 'COUPON_REDEEMED';
export const CLOSE_GREET_MODAL = 'CLOSE_GREET_MODAL';
export const GET_COUNTRY = 'GET_COUNTRY';
export const TEST_REDEEMED = 'TEST_REDEEMED';
export const SUCCESS_MODAL_CLOSE = 'SUCCESS_MODAL_CLOSE';


//Test 
export const GET_TESTS = 'GET_TESTS';
export const CHECK_TEST = 'CHECK_TEST';
export const GET_OLD_TESTS = 'GET_OLD_TESTS';
export const TEST_HISTORY = 'TEST_HISTORY';
export const NEXT_TEST_HISTORY = 'NEXT_TEST_HISTORY';
export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const GET_REPORT = 'GET_REPORT';

//Analytics
export const GET_SKILL_STATUS = 'GET_SKILL_STATUS';
export const GET_ENGAGEMENT_SCORE = 'GET_ENGAGEMENT_SCORE';
export const QUIZ_PERFORMANCE = 'QUIZ_PERFORMANCE';
export const GET_CONSUMPTION = 'GET_CONSUMPTION';
export const GET_MONTHLY_DATA = 'GET_MONTHLY_DATA';
export const GET_SKILL_PERCENT = 'GET_SKILL_PERCENT';
//Achievers
export const GET_ACHIEVERS_LIST = 'GET_ACHIEVERS_LIST';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const APPERCIATION = 'APPERCIATION';


//My content
export const GET_CONTENT = 'GET_CONTENT';
export const GET_NEXT_CONTENT = 'GET_NEXT_CONTENT';
export const GET_RELEVENT_SKILL = 'GET_RELEVENT_SKILL';
export const SUBMIT_URL = 'SUBMIT_URL';
export const GET_CONTENT_PERCENT = 'GET_CONTENT_PERCENT';
export const GET_CONTENTS = 'GET_CONTENTS';
export const GET_CONTENT_STATE = 'GET_CONTENT_STATE';
export const RESET_PERCENT = 'RESET_PERCENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const GET_CONTENT_LIKES = 'GET_CONTENT_LIKES';
export const GET_CONTENT_SAVED = 'GET_CONTENT_SAVED';
export const UPDATE_URL = 'UPDATE_URL';


//notification
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NEXT_NOTIFICATION = 'GGET_SCOREET_NEXT_NOTIFICATION';

//supersearch
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_NEXT_SEARCH_RESULTS = 'GET_NEXT_SEARCH_RESULTS';
export const REFRESH_CONTENT = 'REFRESH_CONTENT';
export const GET_SEARCH_LIKES = 'GET_SEARCH_LIKES';
export const GET_SAVED_SEARCH_RESULT = 'GET_SAVED_SEARCH_RESULT';


//profile
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ADD_EDUCATION = 'ADD_EDUCATION';
export const GET_COLLEGES = 'GET_COLLEGES';
export const GET_COURSES = 'GET_COURSES';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';

// saved 
//skillpods
export const GET_SKILL_PODS = 'GET_SKILL_PODS';
export const ADD_SKILL_POD = 'ADD_SKILL_POD';
export const CLOSE_ADD_SKILL_POD = 'CLOSE_ADD_SKILL_POD';
export const ADD_SKILL_POD_MODAL = 'ADD_SKILL_POD_MODAL';
export const EDIT_SKILL_POD = 'EDIT_SKILL_POD';
export const GET_PODS_RESOURCES = 'GET_PODS_RESOURCES';
export const GET_CONTENT_FOR_PODS = 'GET_CONTENT_FOR_PODS';
export const GET_NEXT_CONTENT_FOR_PODS = 'GET_NEXT_CONTENT_FOR_PODS';
export const ADD_RESOURCE_TO_POD = 'ADD_RESOURCE_TO_POD';
export const REMOVE_RESOURCE_TO_POD = 'REMOVE_RESOURCE_TO_POD';
export const GET_RESOURCE_DETAILS = 'GET_RESOURCE_DETAILS';
export const RESET_POD_DATA = 'RESET_POD_DATA';
export const CLOSE_FEED_POPUP = 'CLOSE_FEED_POPUP';
export const CAPTURE_ANALYTIC = 'CAPTURE_ANALYTIC';
export const REORDER_CONTENT = 'REORDER_CONTENT';
export const GET_SHARED_PODS = 'GET_SHARED_PODS';
export const GET_LIKED_CONTENT = 'GET_LIKED_CONTENT';
export const GET_LIKED_SUMMARY = 'GET_LIKED_SUMMARY';
export const GET_NEXT_LIKED_CONTENT = 'GET_NEXT_LIKED_CONTENT';
export const LIKES_LIKE = 'LIKES_LIKE';
export const GET_SEARCH_LIKED_CONTENT = 'GET_SEARCH_LIKED_CONTENT';
export const RESET_CONTENT_lOADING = 'RESET_CONTENT_lOADING';


// skill library
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS';
export const GET_NEXT_ALL_SKILLS = 'GET_NEXT_ALL_SKILLS';
export const GET_SEARCH_SKILLS = 'GET_SEARCH_SKILLS';
export const ADD_SKILLS = 'ADD_SKILLS';


// Admin side
//Users
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_NEXT_USER_LIST = 'GET_NEXT_USER_LIST';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const GET_USER_SKILLS = 'GET_USER_SKILLS';
export const CHECK_USER = 'CHECK_USER';
export const INVITE_SENT = 'INVITE_SENT';
export const GET_TEAMS = 'GET_TEAMS';
export const TEAM_ADDED = 'TEAM_ADDED';
export const ADD_TO_TEAM = 'ADD_TO_TEAM';
export const SET_ACTIVE = 'SET_ACTIVE';
export const GET_TEAM_USER = 'GET_TEAM_USER';
export const REMOVE_FROM_TEAM = 'REMOVE_FROM_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const SORT_TEAMS = 'SORT_TEAMS';
export const TEAM_EDIT = 'TEAM_EDIT';
export const SEARCH_JOB_TITLES = 'SEARCH_JOB_TITLES';
export const DETAILS_UPDATE = 'DETAILS_UPDATE';
export const DELETE_USER = 'DELETE_USER';


//Admin Tests
export const SET_ACTIVE_BTN = 'SET_ACTIVE_BTN';
export const GET_ADMIN_TEST = 'GET_ADMIN_TEST';
export const GET_NEXT_ADMIN_TEST = 'GET_NEXT_ADMIN_TEST';
export const GET_ADMIN_V2_TEST = 'GET_ADMIN_V2_TEST';
export const RESET_TEST_LOADING = 'RESET_TEST_LOADING';
export const GET_EMAIL_SUGGESTIONS = 'GET_EMAIL_SUGGESTIONS';
export const GET_SKILLS_SUGGESTIONS = 'GET_SKILLS_SUGGESTIONS';
export const GET_TEST_VERSIONS = 'GET_TEST_VERSIONS';
export const SCHEDULED_DONE = ' SCHEDULED_DONE';
export const SCHEDULED_FAILED = 'SCHEDULED_FAILED';
export const CLOSE_INVITE = 'CLOSE_INVITE';
export const ACTION_DONE = 'ACTION_DONE';
export const GET_USERS_DATA = 'GET_USERS_DATA';
export const SEARCH_QUIZ = 'SEARCH_QUIZ';
export const GET_QUIZZES = 'GET_QUIZZES';
export const SEARCH_TEST = 'SEARCH_TEST';
export const GET_CUSTOM_TESTS = 'GET_CUSTOM_TESTS';
export const Add_Question_Quiz = 'Add_Question_Quiz';
export const ADD_QUIZ_TO_POD = 'ADD_QUIZ_TO_POD';
export const Delete_Question_Quiz = 'Delete_Question_Quiz';
export const Delete_All_Question_Quiz = 'Delete_All_Question_Quiz';
export const CHANGE_TEST_STATUS = 'CHANGE_TEST_STATUS';
export const BACK_TO_VIEW = 'BACK_TO_VIEW';
export const GET_QUESTION_SKILLS = 'GET_QUESTION_SKILLS';
export const SEARCH_SKILLS = 'SEARCH_SKILLS';
export const GET_QUESTIONS_FROM_SKILL = 'GET_QUESTIONS_FROM_SKILL';
export const SEARCH_QUESTION = 'SEARCH_QUESTION';
export const RESET_QUES_DATA = 'RESET_QUES_DATA';
export const SET_QUESTION_TITLE = 'SET_QUESTION_TITLE';
export const SET_CHOICE1 = 'SET_CHOICE1';
export const SET_CHOICE2 = 'SET_CHOICE2';
export const SET_CHOICE3 = 'SET_CHOICE3';
export const SET_CHOICE4 = 'SET_CHOICE4';
export const SET_EXPLAINTION = 'SET_EXPLAINTION';
export const GET_DATA_FOR_EDIT = 'GET_DATA_FOR_EDIT';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';


//Admin contents
export const GET_CONTENT_LIST = 'GET_CONTENT_LIST';
export const GET_NEXT_CONTENT_LIST = 'GET_NEXT_CONTENT_LIST';
export const APPROVED = 'APPROVED';
export const RESET_CONTENT = 'RESET_CONTENT';
export const CHANGE_CONTENT_STATUS = 'CHANGE_CONTENT_STATUS';

//admin rewards

export const GET_REWARDS_LIST = 'GET_REWARDS_LIST';
export const GET_NEXT_REWARDS_LIST = 'GET_NEXT_REWARDS_LIST';
export const GET_REWARDS_SEARCH = 'GET_REWARDS_SEARCH';
export const RESET_SEARCH_LOADING = 'RESET_SEARCH_LOADING';


//job titles

export const GET_JOB_TITLES = 'GET_JOB_TITLES';
export const CHANGE_JOB_TITLE_STATUS = 'CHANGE_JOB_TITLE_STATUS';
export const GET_ROLE_SKILL = 'GET_ROLE_SKILL';
export const GET_SUGGESTED_SKILLS = 'GET_SUGGESTED_SKILLS';
export const IS_EXIST = 'IS_EXIST';
export const CLEAR_JOB_TITLES = 'CLEAR_JOB_TITLES';
export const GET_SEARCH_ROLE = 'GET_SEARCH_ROLE';
export const GET_SEARCH_SKILL = 'GET_SEARCH_SKILL';
export const ADD_SKILL_TO_SELECTED_ROLE = 'ADD_SKILL_TO_SELECTED_ROLE';
export const REORDER_SKILL = 'REORDER_SKILL';
export const SEARCH_JOB_TITLE = 'SEARCH_JOB_TITLE';
export const REMOVE_SKILL = 'REMOVE_SKILL';
export const RENAME_JOB_TITLE = 'RENAME_JOB_TITLE';
export const CREATE_JOB_TITLE = 'CREATE_JOB_TITLE';
export const UPDATE_JOB_TITLE = 'UPDATE_JOB_TITLE';
export const REFRESH_SKILLS = 'REFRESH_SKILLS';

//account
export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const APPLY_THEME = 'APPLY_THEME';
export const GET_COMPANY_COLORS = 'GET_COMPANY_COLORS';
export const GET_COMPANY_PLANS = 'GET_COMPANY_PLANS';
export const SHOW_WARNING = 'SHOW_WARNING';
export const HIDE_WARNING = 'HIDE_WARNING';

//admin reports
export const GET_USER_BY_TEAM = 'GET_USER_BY_TEAM';
export const GET_USER_BY_ROLE = 'GET_USER_BY_ROLE';
export const GET_LOGIN_TRENDS = 'GET_LOGIN_TRENDS';
export const GET_CONTENT_CONSUMPTION = 'GET_CONTENT_CONSUMPTION';
export const GET_ENG_SCORE = 'GET_ENG_SCORE';
export const GET_SKILL_PROGRESS = 'GET_SKILL_PROGRESS';
export const GET_USER_PROGRESS = 'GET_USER_PROGRESS';
export const GET_NEXT_USER_PROGRESS = ' GET_NEXT_USER_PROGRESS';
export const GET_NEXT_SKILL_PROGRESS = 'GET_NEXT_SKILL_PROGRESS';


//Manage skill path
export const GET_ALL_SKILL = 'GET_ALL_SKILL';
export const CHANGE_SKILL_STATUS = 'CHANGE_SKILL_STATUS';
export const SEARCH_ALL_SKILL = 'SEARCH_ALL_SKILL';
export const RESET_SKILL_PATH = 'RESET_SKILL_PATH';
export const CHECK_SKILLPATH_LENGTH = 'CHECK_SKILLPATH_LENGTH';
export const GET_CUSTOM_SKILL_PATH = 'GET_CUSTOM_SKILL_PATH';
export const GET_NEXT_CUSTOM_SKILL_PATH = 'GET_NEXT_CUSTOM_SKILL_PATH';
export const GET_SKILL = 'GET_SKILL';
export const SEARCH_SKILL = 'SEARCH_SKILL';
export const CREATE_CUSTOM_SKILLPATH = 'CREATE_CUSTOM_SKILLPATH';
export const EDIT_SKILL_PATH = 'EDIT_SKILL_PATH';
export const GET_SKILL_PATH_DETAILS = 'GET_SKILL_PATH_DETAILS';
export const GET_CUSTOM_SKILL_PODS = 'GET_CUSTOM_SKILL_PODS';
export const CHANGE_SKILLPATH_STATUS = 'CHANGE_SKILLPATH_STATUS';
export const RESET_SKILL_PODS = 'RESET_SKILL_PODS';
export const ADD_SKILL_PODs = 'ADD_SKILL_PODs';
export const CHANGE_SKILLPOD_STATUS = 'CHANGE_SKILLPOD_STATUS';
export const REORDER_SKILLPOD = 'REORDER_SKILLPOD';
export const GET_SUBTOPICS = 'GET_SUBTOPICS';
export const GET_SUBTOPICS_FAILED = 'GET_SUBTOPICS_FAILED';
export const ADD_SUBTOPIC = 'ADD_SUBTOPIC';
export const EDIT_SUBTOPIC = 'ADD_SUBTOPIC';
export const CHANGE_SUBTOPIC_STATUS = 'CHANGE_SUBTOPIC_STATUS';
export const REORDER_SUBTOPIC = 'REORDER_SUBTOPIC';
export const RESET_RESOURCES = 'RESET_RESOURCES';
export const GET_SUBTOPIC_RESOURCES = 'GET_SUBTOPIC_RESOURCES';
export const CHANGE_RESOURCE_STATUS = 'CHANGE_RESOURCE_STATUS';
export const REORDER_RESOURCES = 'REORDER_RESOURCES';
export const GET_PREVIEW = 'GET_PREVIEW';
export const NULL_PREVIEW = 'NULL_PREVIEW';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const GET_DOCUMENTS_STATE = 'GET_DOCUMENTS_STATE';
export const GET_DOCUMENTS_PERCENT = 'GET_DOCUMENTS_PERCENT';
export const RESET_PERCENTAGE = 'RESET_PERCENTAGE';
export const CHANGE_SOURCE = 'CHANGE_SOURCE';


//Manage Mentors
export const MENTOR_SKILL = 'MENTOR_SKILL';
export const GET_MENTOR_LIST = 'GET_MENTOR_LIST';
export const GET_NEXT_MENTOR_LIST = 'GET_NEXT_MENTOR_LIST';
export const ADD_MENTOR = 'ADD_MENTOR';
export const CHANGE_MENTOR_STATUS = 'CHANGE_MENTOR_STATUS';
export const MENTOR_JOB_TITLES = 'MENTOR_JOB_TITLES';
export const GET_MENTORS = 'GET_MENTORS';
export const ADMIN_REPLY_SENT = 'ADMIN_REPLY_SENT';
export const DELETE_REPLY = 'DELETE_REPLY';

//Mentors
export const RESET_QUERY_LOADING = 'RESET_QUERY_LOADING';
export const GET_CONVERSATION_LIST = 'GET_CONVERSATION_LIST';
export const GET_NEXT_CONVERSATION_LIST = 'GET_NEXT_CONVERSATION_LIST';
export const ADD_QUERY = 'ADD_QUERY';
export const RESET_QUERY = 'RESET_QUERY';
export const Get_QUES_DETAILS = 'Get_QUES_DETAILS';
export const Close_Question_Details = 'Close_Question_Details';
export const REPLY_SENT = 'REPLY_SENT';
export const JUNK_CONVERSATION = 'JUNK_CONVERSATION';
export const CLOSED_CONVERSATION = 'CLOSED_CONVERSATION';
export const BOOK_SESSION = 'BOOK_SESSION';

//protoctored test
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTION = 'GET_QUESTION';
export const CLOSED_TEST = 'CLOSED_TEST';
export const GET_TEST_ANSWERS = 'GET_TEST_ANSWERS';
export const GET_SCORE = 'GET_SCORE';
export const GET_IMAGE_UPLOAD = 'GET_IMAGE_UPLOAD';


//Feedback
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const FEEDBACK_SKILL = 'FEEDBACK_SKILL';