import {
    GET_COMPANY_DETAILS, EDIT_COMPANY, APPLY_THEME, GET_COMPANY_COLORS, GET_COMPANY_PLANS, SHOW_WARNING, HIDE_WARNING
} from '../../actionType/type';

let intialState = {
    theme: (JSON.parse(localStorage.getItem('theme')) || {}),
    alert: false,
    plans: {}
}

export default function accountReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_COMPANY_DETAILS:
            stateCopy.companyDetails = action.payload;
            localStorage.setItem('company', action.payload.name)
            return stateCopy;
        case EDIT_COMPANY:
            return stateCopy;
        case GET_COMPANY_COLORS:
            stateCopy.theme = action.payload;
            localStorage.setItem('theme', JSON.stringify(action.payload));
            return stateCopy;
        case APPLY_THEME:
            stateCopy.theme = action.payload;
            return stateCopy;
        case GET_COMPANY_PLANS:
            stateCopy.plans = action.payload;
            localStorage.setItem('plans', JSON.stringify(action.payload))
            return stateCopy;
        case SHOW_WARNING:
            stateCopy.alert = true;
            return stateCopy;
        case HIDE_WARNING:
            stateCopy.alert = false;
            return stateCopy;
        default:
            return state;
    }
}