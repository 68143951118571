import {
    GET_SEARCH_RESULTS, GET_NEXT_SEARCH_RESULTS, REFRESH_CONTENT, GET_RESOURCE_DETAIL, CLOSE_FEED, GET_SEARCH_LIKES, GET_SAVED_SEARCH_RESULT
} from '../actionType/type';

let intialState = {
    isOpenFeed: false,
    isLoading: false,
    isFeed: false,
    noResults: false,
    activeFeedData: { note: null },
}



export default function searchReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_SEARCH_RESULTS:
            if (action.payload.length >= 1) {
                stateCopy.searchResults = action.payload;
                stateCopy.noResults = false;
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.noResults = true;
                stateCopy.isLoading = false;
            }
            return stateCopy;
        case GET_NEXT_SEARCH_RESULTS:
            stateCopy.searchResults = stateCopy.searchResults.concat(action.payload);
            stateCopy.isLoading = false;
            stateCopy.noResults = false;
            return stateCopy;
        case REFRESH_CONTENT:
            stateCopy.isLoading = true;
            stateCopy.searchResults = [];
            return stateCopy;
        case GET_RESOURCE_DETAIL:
            if (action.payload.allow) {
                stateCopy.activeFeedData = action.payload.data;
                stateCopy.allow = action.payload.allow;
                stateCopy.isOpenFeed = true;
            }
            else {
                stateCopy.allow = action.payload.allow;
                stateCopy.expireMessage = action.payload.message;
            }
            return stateCopy;
        case CLOSE_FEED:
            stateCopy.activeFeedData = {};
            stateCopy.isOpenFeed = false;
            return stateCopy;
        case GET_SEARCH_LIKES:
            const search = stateCopy.searchResults.map(answer => {
                if (answer._id === action.payload.r_id)
                    return {
                        _id: answer._id,
                        duration: answer.duration,
                        description: answer.description,
                        image: answer.image,
                        liked: !action.payload.liked,
                        likedCount: answer.likedCount,
                        pod_id: answer.pod_id,
                        source: answer.source,
                        source_image: answer.source_image,
                        skills: answer.skills,
                        title: answer.title,
                        url: answer.url,
                        type: answer.type,
                        viewCount: answer.viewCount
                    }
                return answer
            })
            stateCopy.searchResults = search;
            if (stateCopy.activeFeedData) {
                stateCopy.activeFeedData.liked = !action.payload.liked;
            }
            return stateCopy;
        case GET_SAVED_SEARCH_RESULT:
            stateCopy.searchResults.forEach(el => {
                if (el._id === action.payload) {
                    el.saved = true;
                }
            }
            )
            return stateCopy;
        default:
            return stateCopy;
    }
}