import { HIDE_SIDEBAR } from '../actionType/type';
import {
    GET_CURRENT_JOB, GET_ASPIRING_JOB, GET_SUGGESTED_ROLE, GET_SUGGESTED_ROLE_SKILL, SAVE_CURRENT_JOB, CLEAR_JOBS, SAVE_ASPIRING_JOB,
    GET_SUGGESTED_SKILL, CLOSE_SKILL_SUGGESTION, SAVE_PROFILE, SAVE_PROFILE1
} from '../actionType/type';

let intialState = {
    isShow: false,
    // selectedCurrnetJob: {}
}

export default function addSkillReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case HIDE_SIDEBAR:
            stateCopy.isShow = true;
            return stateCopy;
        case GET_CURRENT_JOB:
            stateCopy.currentJob = action.payload;
            localStorage.setItem('currentJob', action.payload.title)
            return stateCopy;
        case GET_ASPIRING_JOB:
            stateCopy.aspiringJob = action.payload;
            return stateCopy;
        case GET_SUGGESTED_ROLE:
            stateCopy.suggestions = action.payload;
            return stateCopy;
        case GET_SUGGESTED_ROLE_SKILL:
            stateCopy.suggestions = [];
            if (action.payload.jobType === 'current') {
                stateCopy.selectedCurrnetJob = action.payload.data;
            }
            else {
                stateCopy.selectedAspiringJob = action.payload.data;
            }
            return stateCopy;
        case SAVE_CURRENT_JOB:
            stateCopy.currentJob = stateCopy.selectedCurrnetJob;
            localStorage.setItem('currentJob', stateCopy.currentJob.title);
            stateCopy.selectedCurrnetJob = null;
            return stateCopy;
        case CLEAR_JOBS:
            if (action.payload === 'current') {
                stateCopy.selectedCurrnetJob = null;
            }
            else {
                stateCopy.selectedAspiringJob = null;
            }
            return stateCopy;
        case SAVE_ASPIRING_JOB:
            stateCopy.aspiringJob = stateCopy.selectedAspiringJob;
            stateCopy.selectedAspiringJob = null;
            return stateCopy;
        case GET_SUGGESTED_SKILL:
            stateCopy.skillSuggestions = action.payload;
            return stateCopy;
        case CLOSE_SKILL_SUGGESTION:
            stateCopy.skillSuggestions = [];
            return stateCopy;
        case SAVE_PROFILE:
            if (action.payload === 'profile') {
                localStorage.setItem('profileComplete', true);
                window.location.href = '/learn'
            }
            else {
                localStorage.setItem('introComplete', true);
            }
            return stateCopy;
        case SAVE_PROFILE1:
            if (action.payload === 'profile') {
                localStorage.setItem('profileComplete', true);
            }
            else {
                localStorage.setItem('introComplete', true);
            }
            return stateCopy;
        default:
            return stateCopy;
    }
}