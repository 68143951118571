import { GET_REWARDS_LIST, GET_NEXT_REWARDS_LIST, GET_REWARDS_SEARCH, RESET_SEARCH_LOADING } from '../../actionType/type';
let intialState = {
    isLoading: true
}


export default function adminRewardReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_REWARDS_LIST:
            stateCopy.rewardList = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_REWARDS_LIST:
            stateCopy.rewardList = stateCopy.rewardList.concat(action.payload);
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_REWARDS_SEARCH:
            stateCopy.rewardList = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case RESET_SEARCH_LOADING:
            stateCopy.isLoading = true;
            return stateCopy;
        default:
            return stateCopy;

    }
}