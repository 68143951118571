import {
    GET_ALL_SKILL, SEARCH_ALL_SKILL, CHANGE_SKILL_STATUS, RESET_SKILL_PATH, GET_CUSTOM_SKILL_PATH, GET_SKILL, SEARCH_SKILL, CREATE_CUSTOM_SKILLPATH, EDIT_SKILL_PATH, GET_SKILL_PATH_DETAILS, GET_CUSTOM_SKILL_PODS,
    CHANGE_SKILLPATH_STATUS, RESET_SKILL_PODS, ADD_SKILL_PODs, CHANGE_SKILLPOD_STATUS, REORDER_SKILLPOD,
    GET_SUBTOPICS, GET_SUBTOPICS_FAILED, ADD_SUBTOPIC, CHANGE_SUBTOPIC_STATUS, REORDER_SUBTOPIC, GET_DOCUMENTS_PERCENT, GET_DOCUMENTS_STATE, RESET_PERCENTAGE,
    RESET_RESOURCES, GET_SUBTOPIC_RESOURCES, CHANGE_RESOURCE_STATUS, REORDER_RESOURCES, GET_PREVIEW, CHANGE_SOURCE, NULL_PREVIEW, ADD_RESOURCE
} from '../../actionType/type';

let intialState = {
    isLoading: true,
    isSkillLoading: true,
    isSkillPathLoading: true,
    isSkillPodLoading: true,
    subTopics: [],
    isSubTopicLoading: true,
    isResourceLoading: true,
    articleSource: '',
    customPathId: ""

}

export default function manageSkillPathReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ALL_SKILL:
            stateCopy.allSkills = action.payload.filter(el => (el.custom || (el.customskillpath > 0))).sort((a, b) =>
                a.title.localeCompare(b.title));
            stateCopy.allSkillsCopy = action.payload.filter(el => (el.custom || (el.customskillpath > 0))).sort((a, b) =>
                a.title.localeCompare(b.title));
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case SEARCH_ALL_SKILL:
            if (action.payload.trim('') === '') {
                stateCopy.allSkills = stateCopy.allSkillsCopy;
            } else {
                const regex = new RegExp(action.payload, "i");
                const filtered = stateCopy.allSkillsCopy.filter(
                    skill => {
                        return regex.test(skill.title)
                    }
                );
                stateCopy.allSkills = filtered.sort((a, b) =>
                    a.title.localeCompare(b.title));;
            }
            return stateCopy;
        case CHANGE_SKILL_STATUS:
            stateCopy.allSkills.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            stateCopy.allSkillsCopy = stateCopy.allSkills;
            return stateCopy;
        case RESET_SKILL_PATH:
            stateCopy.customSkillPaths = [];
            stateCopy.isSkillPathLoading = true;
            return stateCopy;
        case GET_CUSTOM_SKILL_PATH:
            stateCopy.customSkillPaths = action.payload;
            stateCopy.customPathId = '';
            stateCopy.isSkillPathLoading = false;
            return stateCopy;
        case GET_SKILL:
            stateCopy.skills = action.payload;
            return stateCopy;
        case SEARCH_SKILL:
            const filtered = stateCopy.skills.filter(el => {
                if (action.payload === '') {
                    return
                }
                else if (el.title.toLowerCase().includes(action.payload.toLowerCase())) {
                    return el
                }
            })
            stateCopy.filteredSkills = filtered;
            return stateCopy;
        case CREATE_CUSTOM_SKILLPATH:
            stateCopy.customPathId = action.payload.skillpath_id;
            return stateCopy
        case EDIT_SKILL_PATH:
            stateCopy.skillPodDetails['title'] = action.payload;
            return stateCopy;
        case RESET_SKILL_PODS:
            stateCopy.isSkillPodLoading = true;
            stateCopy.skillPodDetails = {};
            stateCopy.skillPods = [];
            return stateCopy;
        case CHANGE_SKILLPATH_STATUS:
            stateCopy.customSkillPaths.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case GET_SKILL_PATH_DETAILS:
            stateCopy.skillPodDetails = action.payload;
            return stateCopy;
        case GET_CUSTOM_SKILL_PODS:
            stateCopy.skillPods = action.payload
                .map((item) => ({
                    ...item,
                    items:
                        item.articles +
                        item.videos +
                        item.podcasts +
                        item.courses +
                        item?.quizzes +
                        item.documents,
                }))
                .sort((a, b) => (a.index > b.index ? 1 : -1));
            stateCopy.isSkillPodLoading = false;
            return stateCopy;
        case ADD_SKILL_PODs:
            stateCopy.skillPods = [...stateCopy.skillPods, action.payload];
            return stateCopy;
        case CHANGE_SKILLPOD_STATUS:
            stateCopy.skillPods.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case REORDER_SKILLPOD:
            const skillpod = Array.from(stateCopy.skillPods);
            const [reorderedSkillPod] = skillpod.splice(
                action.payload.source.index,
                1
            );
            skillpod.splice(action.payload.destination.index, 0, reorderedSkillPod);
            skillpod.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.skillPods = skillpod;
            return stateCopy;
        case GET_SUBTOPICS:
            stateCopy.subTopics = action.payload.sort((a, b) =>
                a.index > b.index ? 1 : -1
            );
            stateCopy.isSubTopicLoading = false;
            return stateCopy;
        case GET_SUBTOPICS_FAILED:
            stateCopy.subTopics = [];
            stateCopy.isSubTopicLoading = false;
            return stateCopy;
        case ADD_SUBTOPIC:
            stateCopy.subTopics = [...stateCopy.subTopics, action.payload];
            return stateCopy;
        case CHANGE_SUBTOPIC_STATUS:
            stateCopy.subTopics.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case REORDER_SUBTOPIC:
            const subtopic = Array.from(stateCopy.subTopics);
            const [reorderedSubtopic] = subtopic.splice(
                action.payload.source.index,
                1
            );
            subtopic.splice(action.payload.destination.index, 0, reorderedSubtopic);
            subtopic.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.subTopics = subtopic;
            return stateCopy;
        case RESET_RESOURCES:
            stateCopy.isResourceLoading = true;
            return stateCopy;
        case GET_SUBTOPIC_RESOURCES:
            stateCopy.resourceList = action.payload.sort((a, b) => a.index > b.index ? 1 : -1);
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case CHANGE_RESOURCE_STATUS:
            stateCopy.resourceList.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case REORDER_RESOURCES:
            const resources = Array.from(stateCopy.resourceList);
            const [reorderedSubtopicResource] = resources.splice(
                action.payload.source.index,
                1
            );
            resources.splice(
                action.payload.destination.index,
                0,
                reorderedSubtopicResource
            );
            resources.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.resourceList = resources;
            return stateCopy;
        case GET_PREVIEW:
            stateCopy.previewData = action.payload;
            stateCopy.articleSource = action.payload.site_name
                ? action.payload.site_name
                : "";
            return stateCopy;
        case CHANGE_SOURCE:
            stateCopy.articleSource = action.payload;
            return stateCopy;
        case NULL_PREVIEW:
            stateCopy.previewData = action.payload;
            stateCopy.articleSource = "";
            return stateCopy;
        case ADD_RESOURCE:
            stateCopy.resourceList = [...stateCopy.resourceList, action.payload];
            stateCopy.articleSource = "";
            return stateCopy;
        case GET_DOCUMENTS_STATE:
            stateCopy.documentDataState = action.payload;
            return stateCopy;
        case GET_DOCUMENTS_PERCENT:
            stateCopy.percent = action.payload;
            return stateCopy;
        case RESET_PERCENTAGE:
            stateCopy.percent = 0;
            stateCopy.percent = 0;
            stateCopy.qTitle = '';
            stateCopy.choice1 = '';
            stateCopy.choice2 = '';
            stateCopy.choice3 = '';
            stateCopy.choice4 = '';
            stateCopy.qExplanation = '';
            return stateCopy;
        default:
            return stateCopy;
    }
}