import {
    RESET_QUERY_LOADING, MENTOR_JOB_TITLES, MENTOR_SKILL, GET_MENTOR_LIST, GET_NEXT_MENTOR_LIST, ADD_MENTOR, CHANGE_MENTOR_STATUS,
    GET_CONVERSATION_LIST, GET_NEXT_CONVERSATION_LIST, ADD_QUERY, RESET_QUERY, Get_QUES_DETAILS, Close_Question_Details,
    ADMIN_REPLY_SENT, REPLY_SENT, JUNK_CONVERSATION, CLOSED_CONVERSATION, GET_MENTORS, DELETE_REPLY
} from "../../actionType/type";
let intialState = {
    isLoading: true,
    activeBtn: 'mentor',
    question: [],
    catagories: [{ value: 'General', label: 'General' }, { value: 'skill', label: 'Skill Related' }, { value: 'Others', label: 'Others' }],
    viewDetails: false,
    query: false,
    attachments: [],
    isSkillLoading: true
};

export default function manageMentorReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_QUERY_LOADING:
            stateCopy.isLoading = true;
            return stateCopy;
        case GET_MENTORS:
            stateCopy.selectMentors = action.payload;
            return stateCopy;
        case MENTOR_JOB_TITLES:
            stateCopy.jobtitles = action.payload;
            return stateCopy;
        case MENTOR_SKILL:
            stateCopy.activeSkills = action.payload;
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case GET_MENTOR_LIST:
            stateCopy.mentors = action.payload;
            stateCopy.mentorsCopy = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_MENTOR_LIST:
            stateCopy.mentors = stateCopy.mentors.concat(action.payload);
            stateCopy.mentorsCopy = stateCopy.mentors.concat(action.payload);
            return stateCopy;
        case ADD_MENTOR:
            stateCopy.activeBtn = 'mentor';
            return stateCopy;
        case CHANGE_MENTOR_STATUS:
            stateCopy.mentors.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;

        case GET_CONVERSATION_LIST:
            stateCopy.conversation = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_CONVERSATION_LIST:
            stateCopy.conversation = stateCopy.conversation.concat(action.payload);
            stateCopy.isLoading = false;
            return stateCopy;
        case ADD_QUERY:
            stateCopy.query = true;
            stateCopy.activeBtn = 'conversation';
            return stateCopy;
        case RESET_QUERY:
            stateCopy.query = false;
            return stateCopy;
        case Get_QUES_DETAILS:
            stateCopy.viewDetails = true;
            stateCopy.replies = action.payload.data;
            stateCopy.question = action.payload.details;
            return stateCopy;
        case Close_Question_Details:
            stateCopy.viewDetails = false;
            return stateCopy;
        case REPLY_SENT:
            stateCopy.attachments = [];
            return stateCopy;
        case ADMIN_REPLY_SENT:
            stateCopy.attachments = [];
            return stateCopy;
        case CLOSED_CONVERSATION:
            stateCopy.viewDetails = false;
            stateCopy.activeTab = 'conversation';
            stateCopy.conversation.forEach(el => {
                if (el._id === action.payload) {
                    el.closed = true;
                }
            })

            stateCopy.attachment = [];
            return stateCopy;
        case JUNK_CONVERSATION:
            stateCopy.viewDetails = false;
            stateCopy.activeTab = 'conversation';
            stateCopy.conversation.forEach(el => {
                if (el._id === action.payload) {
                    el.junk = true;
                }
            })

            stateCopy.attachment = [];
            return stateCopy;
        case DELETE_REPLY:
            stateCopy.replies = stateCopy.replies.filter(el => el._id !== action.payload)
            return stateCopy;
        default:
            return stateCopy;
    }
}


