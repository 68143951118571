/* eslint-disable no-redeclare */
import {
    GET_FEEDS, GET_SKILL_FILTER, GET_NEXT_FEEDS, SKILL_CLICKED, RESET_FEEDS, GET_FILTERED_FEEDS, GET_SAVED, OPEN_SHARE,
    CLOSE_SHARE, SHARED, GET_RESOURCE_DETAIL, GET_FEED_LIKES, CLOSE_FEED
} from '../actionType/type';

let intialState = {
    isOpenFeed: false,
    isLoading: false,
    isLoadingSkill: true,
    isFeed: false,
    noResults: false,
    activeFeedData: { note: null },
    contentFilter: [{ title: "Article", value: false }, { title: "Document", value: false }, { title: "Podcast", value: false }, { title: "Video", value: false }, { title: "Course", value: false }]
}



export default function feedsReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_FEEDS:
            if (action.payload.length >= 1) {
                stateCopy.feedList = action.payload;
                stateCopy.noResults = false;
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.noResults = true;
                stateCopy.isLoading = false;
            }
            return stateCopy;
        case GET_NEXT_FEEDS:
            stateCopy.feedList = stateCopy.feedList.concat(action.payload);
            stateCopy.isLoading = false;
            stateCopy.noResults = false;
            return stateCopy;
        case GET_SKILL_FILTER:
            stateCopy.skillFilter = action.payload.map(el => [{ value: false, title: el.title, _id: el._id }][0]);
            stateCopy.skillFilterCopy = action.payload.map(el => [{ value: false, title: el.title, _id: el._id }][0]);
            stateCopy.isLoadingSkill = false;
            return stateCopy;
        case SKILL_CLICKED:
            stateCopy.isLoading = true;
            stateCopy.noResults = false;
            var skillFilters = stateCopy.skillFilter.map(answer => {
                if (answer.title === action.payload.title) return { title: answer.title, value: !action.payload.value, _id: answer._id }
                return answer
            })
            var contentFilters = stateCopy.contentFilter.map(answer => {
                if (answer.title === action.payload.title) return { title: answer.title, value: !action.payload.value }
                return answer
            })
            stateCopy.skillFilter = skillFilters
            stateCopy.contentFilter = contentFilters
            var isSkillSelected = stateCopy.skillFilter.filter((e) => {
                return e.value === true
            })
            var isFeedsTypeSelected = stateCopy.contentFilter.filter((e) => {
                return e.value === true
            })
            stateCopy.skillSelected = isSkillSelected;
            stateCopy.checkIsSkill = isSkillSelected.length > 0 ? JSON.stringify(stateCopy.skillFilter.map(el => [{ _id: el._id, value: el.value }][0])) : "";
            stateCopy.checkIsType = isFeedsTypeSelected.length > 0 ? JSON.stringify(stateCopy.contentFilter) : "";
            return stateCopy;
        case GET_FILTERED_FEEDS:
            stateCopy.feedList = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case RESET_FEEDS:
            stateCopy.feedList = [];
            stateCopy.isLoading = true;
            stateCopy.contentFilter = [{ title: "Article", value: false }, { title: "Document", value: false }, { title: "Podcast", value: false }, { title: "Video", value: false }, { title: "Course", value: false }];
            stateCopy.skillFilter = stateCopy.skillFilterCopy;
            stateCopy.skillSelected = [];
            return stateCopy;
        case GET_SAVED:
            stateCopy.feedList.forEach(el => {
                if (el._id === action.payload) {
                    el.saved = true;
                }
            }
            )
            return stateCopy;
        case OPEN_SHARE:
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case CLOSE_SHARE:
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case SHARED:
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case GET_RESOURCE_DETAIL:
            if (action.payload.allow) {
                stateCopy.activeFeedData = action.payload.data;
                stateCopy.allow = action.payload.allow;
                stateCopy.isOpenFeed = true;
            }
            else {
                stateCopy.allow = action.payload.allow;
                stateCopy.expireMessage = action.payload.message;
            }
            return stateCopy;
        case GET_FEED_LIKES:
            const feeds = stateCopy.feedList.map(answer => {
                if (answer._id === action.payload.r_id)
                    return {
                        _id: answer._id,
                        duration: answer.duration,
                        description: answer.description,
                        image: answer.image,
                        liked: !action.payload.liked,
                        likedCount: answer.likedCount,
                        pod_id: answer.pod_id,
                        source: answer.source,
                        source_image: answer.source_image,
                        skills: answer.skills,
                        note: stateCopy.activeFeedData.note,
                        title: answer.title,
                        url: answer.url,
                        type: answer.type,
                        viewCount: answer.viewCount
                    }
                return answer
            })
            stateCopy.feedList = feeds;
            if (stateCopy.activeFeedData) {
                stateCopy.activeFeedData.liked = !action.payload.liked;
            }
            return stateCopy;
        // case SHOW_FEED:
        //     stateCopy.activeFeedData = action.payload;
        //     stateCopy.isOpenFeed = true;
        //     return stateCopy;
        case CLOSE_FEED:
            stateCopy.activeFeedData = {};
            stateCopy.isOpenFeed = false;
            return stateCopy;
        default:
            return stateCopy;

    }
}

