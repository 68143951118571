import {
    GET_TESTS, CHECK_TEST, GET_OLD_TESTS, TEST_HISTORY, NEXT_TEST_HISTORY, GET_CERTIFICATE, GET_REPORT
} from '../actionType/type';

let intialState = {
    successSchedule: false,
    noVersions: false
}




export default function testReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_TESTS:
            stateCopy.tests = action.payload;
            const present = action.payload.filter(el => (el.test)).sort((a, b) => a.title.localeCompare(b.title));
            const absent = action.payload.filter(el => (!el.test)).sort((a, b) => a.title.localeCompare(b.title));
            stateCopy.pendingTests = present.concat(absent);
            stateCopy.completedTests = action.payload.filter(el => el.test_passed);
            return stateCopy;
        case CHECK_TEST:
            stateCopy.start_test = action.payload.start_test;
            if (!action.payload.start_test) {
                stateCopy.consumedContent = action.payload.consumed.articles + action.payload.consumed.videos + action.payload.consumed.podcasts + action.payload.consumed.courses;
                stateCopy.last_date = action.payload.last_date;
            } else {
                stateCopy.consumedContent = 0;
            }
            return stateCopy;
        case GET_OLD_TESTS:
            stateCopy.oldTestResult = action.payload;
            return stateCopy;
        case TEST_HISTORY:
            stateCopy.testHistory = action.payload;
            return stateCopy;
        case NEXT_TEST_HISTORY:
            stateCopy.testHistory = stateCopy.testHistory.concat(action.payload);
            return stateCopy;
        case GET_CERTIFICATE:
            stateCopy.cHtml = action.payload;
            return stateCopy;
        case GET_REPORT:
            stateCopy.reportData = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}