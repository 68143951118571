import {
    GET_JOB_TITLES, SEARCH_JOB_TITLE, CHANGE_JOB_TITLE_STATUS, GET_SEARCH_ROLE, GET_ROLE_SKILL, GET_SUGGESTED_SKILLS, IS_EXIST, CLEAR_JOB_TITLES, GET_SEARCH_SKILL, ADD_SKILL_TO_SELECTED_ROLE, REORDER_SKILL, REMOVE_SKILL,
    RENAME_JOB_TITLE, REFRESH_SKILLS, CREATE_JOB_TITLE
} from '../../actionType/type';
let intialState = {
    jobTitles: [],
    isLoading: true,
    exist: false,
    selectedJobTitleSkills: []
}


export default function jobTitleReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_JOB_TITLES:
            stateCopy.jobTitles = action.payload;
            stateCopy.jobTitlesCopy = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case SEARCH_JOB_TITLE:
            if (action.payload === '') {
                stateCopy.jobTitles = stateCopy.jobTitlesCopy;
            }
            else {
                const filterResult = stateCopy.jobTitlesCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.jobTitles = filterResult;
            }
            return stateCopy;

        case CHANGE_JOB_TITLE_STATUS:
            stateCopy.jobTitles.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = action.payload.checked ? true : false;
                }
            });
            stateCopy.jobTitlesCopy = stateCopy.jobTitles;
            return stateCopy;
        case GET_SEARCH_ROLE:
            if (action.payload.data.length > 0) {
                stateCopy.suggestions = action.payload.data;
            }
            else {
                let EmptyList = [];
                EmptyList.push({
                    title: action.payload.val,
                    flag: true,
                });
                stateCopy.suggestions = EmptyList;
            }
            return stateCopy;
        case GET_ROLE_SKILL:
            stateCopy.selectedJobTitleSkills = action.payload.data.skills.sort((a, b) => a.weight - b.weight).map((el, i) => ({ ...el, total: el.articles + el.videos + el.courses + el.documents + el.podcasts, weight: i })).filter(el => el.total !== 0);
            stateCopy.jobTitle = action.payload.data.title || action.payload.jobtitle;
            stateCopy.roleId = action.payload.jobid;
            stateCopy.suggestions = [];
            stateCopy.exist = false;
            return stateCopy;
        case GET_SUGGESTED_SKILLS:
            stateCopy.suggestedSkills = action.payload.map(item => ({ ...item, total: item.articles + item.videos + item.courses + item.documents + item.podcasts })).filter(el => el.total !== 0);
            stateCopy.suggestedSkillsCopy = action.payload.map(item => ({ ...item, total: item.articles + item.videos + item.courses + item.documents + item.podcasts })).filter(el => el.total !== 0);
            return stateCopy;
        case REFRESH_SKILLS:
            stateCopy.selectedJobTitleSkills = [];
            stateCopy.jobTitle = action.payload?.data?.title || action.payload?.jobtitle;
            // stateCopy.roleId = action.payload?.jobid;
            stateCopy.suggestions = [];
            return stateCopy;
        case IS_EXIST:
            const exist = stateCopy.jobTitles.some(el => el.title.toLowerCase() === action.payload.toLowerCase());
            if (exist) {
                stateCopy.exist = true;
            }
            else {
                stateCopy.exist = false;
            }
            return stateCopy;
        case CLEAR_JOB_TITLES:
            stateCopy.suggestions = [];
            stateCopy.selectedJobTitleSkills = [];
            stateCopy.jobTitle = '';
            stateCopy.roleId = '';
            stateCopy.suggestedSkills = [];
            return stateCopy;
        case GET_SEARCH_SKILL:
            if (action.payload === '') {
                stateCopy.skillSuggestions = [];
            }
            else {
                const filterResult = stateCopy.suggestedSkillsCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.skillSuggestions = filterResult;
            }
            return stateCopy;
        case ADD_SKILL_TO_SELECTED_ROLE:
            const filter = stateCopy.suggestedSkills.filter(el => el._id !== action.payload._id);
            const filterCopy = stateCopy.suggestedSkillsCopy.filter(el => el._id !== action.payload._id);
            stateCopy.suggestedSkills = filter;
            stateCopy.suggestedSkillsCopy = filterCopy;
            stateCopy.selectedJobTitleSkills = [...stateCopy.selectedJobTitleSkills, action.payload].map((el, i) => ({ ...el, skill_id: el._id || el.skill_id, weight: i }));
            stateCopy.skillSuggestions = [];
            return stateCopy;
        case REORDER_SKILL:
            const items = Array.from(stateCopy.selectedJobTitleSkills);
            const [reorderedItem] = items.splice(action.payload.source.index, 1);
            items.splice(action.payload.destination.index, 0, reorderedItem);
            items.forEach((el, i) => {
                if (el.weight !== i + 1) {
                    el.weight = i + 1;
                }
            });
            stateCopy.selectedJobTitleSkills = items;
            return stateCopy;
        case REMOVE_SKILL:
            const filtered = stateCopy.selectedJobTitleSkills.filter(el => el.skill_id !== action.payload.skill_id);
            stateCopy.suggestedSkills = [...stateCopy.suggestedSkills, action.payload];
            stateCopy.suggestedSkillsCopy = [...stateCopy.suggestedSkillsCopy, action.payload];
            stateCopy.selectedJobTitleSkills = filtered;
            return stateCopy;
        case RENAME_JOB_TITLE:
            stateCopy.jobTitle = action.payload;
            stateCopy.suggestions = [];
            const exist1 = stateCopy.jobTitles.some(el => el.title.toLowerCase() === action.payload.toLowerCase());
            if (exist1) {
                stateCopy.exist = true;
            }
            else {
                stateCopy.exist = false;
            }
            return stateCopy;
        case CREATE_JOB_TITLE:
            stateCopy.jobs = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}