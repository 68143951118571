import {
    GET_SKILL_PODS, RESET_POD_DATA, CLOSE_ADD_SKILL_POD, ADD_SKILL_POD_MODAL, ADD_SKILL_POD, EDIT_SKILL_POD, GET_PODS_RESOURCES, GET_CONTENT_FOR_PODS, GET_NEXT_CONTENT_FOR_PODS,
    REORDER_CONTENT, ADD_RESOURCE_TO_POD, REMOVE_RESOURCE_TO_POD, GET_RESOURCE_DETAILS, CLOSE_FEED_POPUP, CAPTURE_ANALYTIC,
    GET_LIKED_CONTENT, GET_LIKED_SUMMARY, GET_NEXT_LIKED_CONTENT, LIKES_LIKE, GET_SEARCH_LIKED_CONTENT, RESET_CONTENT_lOADING, GET_SHARED_PODS
} from '../actionType/type';
let intialState = {
    isOpen: false,
    isPodLoading: true,
    isResourceLoading: true,
    isFeedOpen: false,
    loading: true,
    contentLoading: true,
    contentForPods: []
}

export default function savedReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_SKILL_PODS:
            stateCopy.podList = action.payload;
            stateCopy.podList = action.payload.map(item => ({
                ...item,
                total: Object.values(item.count).reduce((a, b) => a + b),
                shared:false
            }));
            stateCopy.isPodLoading = false;
            return stateCopy;
        case ADD_SKILL_POD_MODAL:
            stateCopy.isOpen = true;
            if (action.payload.item !== null) {
                stateCopy.editObj = action.payload.item;
                stateCopy.edit = action.payload.edit;
            } else {
                stateCopy.edit = action.payload.edit;
            }
            return stateCopy;
        case CLOSE_ADD_SKILL_POD:
            stateCopy.isOpen = false;
            stateCopy.editObj = null;
            stateCopy.edit = false;
            return stateCopy;
        case ADD_SKILL_POD:
            stateCopy.podDetails = action.payload;
            stateCopy.isOpen = false;
            stateCopy.edit = false;
            return stateCopy;
        case EDIT_SKILL_POD:
            stateCopy.podDetails = { ...stateCopy.podDetails, title: action.payload.title, image: action.payload.imageUrl }
            stateCopy.isOpen = false;
            stateCopy.edit = false;
            return stateCopy;
        case GET_PODS_RESOURCES:
            stateCopy.podResources = action.payload.resources.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.podDetails = action.payload;
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case REORDER_CONTENT:
            const contents = Array.from(stateCopy.podResources);
            const [reorderedItem] = contents.splice(action.payload.source.index, 1);
            contents.splice(action.payload.destination.index, 0, reorderedItem);
            contents.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.podResources = contents;
            return stateCopy;
        case GET_CONTENT_FOR_PODS:
            stateCopy.contentForPods = action.payload;
            stateCopy.contentLoading = false;
            return stateCopy;
        case GET_NEXT_CONTENT_FOR_PODS:
            stateCopy.contentForPods = stateCopy.contentForPods.concat(action.payload);
            stateCopy.contentLoading = false;
            return stateCopy;
        case ADD_RESOURCE_TO_POD:
            stateCopy.contentForPods.forEach(answer => {
                if (answer._id === action.payload) {
                    answer.add = true
                }
            })
            return stateCopy;
        case REMOVE_RESOURCE_TO_POD:
            const removed = stateCopy.podResources.filter(el => { return el._id !== action.payload });
            stateCopy.podContents = removed;
            return stateCopy;
        case RESET_POD_DATA:
            stateCopy.podResources = [];
            stateCopy.isResourceLoading = true;
            return stateCopy;
        case GET_RESOURCE_DETAILS:
            stateCopy.isFeedOpen = true;
            stateCopy.activeFeedData = action.payload.data;
            stateCopy.allow = action.payload.allow;
            return stateCopy;
        case CLOSE_FEED_POPUP:
            stateCopy.activeFeedData = {};
            stateCopy.isFeedOpen = false;
            return stateCopy;
        case CAPTURE_ANALYTIC:
            const analytics = stateCopy.podResources.map(answer => {
                if (answer._id === action.payload.r_id) return {
                    _id: answer._id,
                    title: answer.title,
                    source: answer.source,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    topic: answer.topic,
                    image: answer.image,
                    index: answer.index,
                    duration: answer.duration,
                    viewed: answer.viewed ? true : action.payload.viewed,
                    // recommended: answer.recommended
                }
                return answer
            })
            stateCopy.resources = analytics;
            return stateCopy;
        case GET_LIKED_SUMMARY:
            stateCopy.likesCount = objectToArray(action.payload);
            return stateCopy;
        case GET_LIKED_CONTENT:
            stateCopy.likeList = action.payload.data.map(el => ({ ...el, type: action.payload.type }));
            stateCopy.loading = false;
            return stateCopy;
        case GET_NEXT_LIKED_CONTENT:
            stateCopy.likeList = stateCopy.likeList.concat(action.payload.data.map(el => ({ ...el, type: action.payload.type })));
            stateCopy.loading = false;
            return stateCopy;
        case LIKES_LIKE:
            stateCopy.activeFeedData.liked = !action.payload.liked;
            return stateCopy;
        case RESET_CONTENT_lOADING:
            stateCopy.contentLoading = true;
            stateCopy.loading = true;
            return stateCopy;
        case GET_SEARCH_LIKED_CONTENT:
            stateCopy.likeList = action.payload.data.map(el => ({ ...el, type: action.payload.type }));
            stateCopy.loading = false;
            return stateCopy;
        case GET_SHARED_PODS:
            stateCopy.sharedPodList = action.payload.map(el => ({ ...el, shared: true }));
            return stateCopy;
        default:
            return state;
    }
}

const objectToArray = (object) => {
    let array = [];
    for (let key in object) {
        array.push({
            type: key === 'videos' ? 'Video' : key === 'articles' ? 'Article' : key === 'podcasts' ? 'Podcast' : key === 'courses' ? 'Course' : key === 'documents' ? 'Document' : null
            , total: object[key] === 'NaN' ? null : object[key], resources: []
        });
    }
    return array;
};